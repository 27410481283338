<template>
  <div class="level-right" style="margin-top: 1.5rem">
    <div class="level-item">
      <span>
        Rows per page:
      </span>
    </div>
    <div class="level-item">
      <b-select :value="pagination.size" @input="onSizeChange($event)">
        <option 
        v-for="option in pageSizeOptionsComputed"  
        :key="option"
        :value="option"
        >
        {{option}}
      </option>
      </b-select>
    </div>
    <div class="level-item">
      <span>
        {{ pageInfoComputed }}
      </span>
    </div>
    <div class="level-item">
      <b-button :disabled="isFirstPage" icon-right="chevron-left" @click="paginateLeft" /> 
    </div>
    <div class="level-item">
      <b-button :disabled="isLastPage" icon-right="chevron-right" @click="paginateRight" />
    </div>
</div>

</template>

<script>
export default {
  name: 'ListTablePagination',
  props: {
    pagination: { type: Object, required: true },
    totalElements: {type: null, required: true},
  },
  data () {
    return {
      defaultSizeOptions: [5, 10, 20, 50]
    }
  },
  computed: {
    pageSizeOptionsComputed () {
      const size = parseInt(this.pagination.size);
      if (!this.defaultSizeOptions.includes(size)) {
        return this.defaultSizeOptions.concat(parseInt(size)).sort((a,b)=>a-b)
      }
      return this.defaultSizeOptions
    },
    pageInfoComputed () {
      const size = parseInt(this.pagination.size);
      const page = parseInt(this.pagination.page);
      const totalElements = parseInt(this.totalElements)

      const lowerBound = totalElements === 0 ? 0 : (page * size + 1); 
      const upperBound = Math.min((page + 1) * size, totalElements); 

      return `${lowerBound} - ${upperBound} of ${totalElements}`
    },
    isFirstPage () {
      const page = parseInt(this.pagination.page);
      return (page === 0)
    },
    isLastPage () {
      const page = parseInt(this.pagination.page)
      const size = parseInt(this.pagination.size)
      const totalElements = parseInt(this.totalElements) 

      return (page + 1) * size >= totalElements // If the total elements is less than or equal to the upper bound of a page, then it must be the last page
    }
  },
  methods: {
    onSizeChange(updateSize) {
      const currentPage = parseInt(this.pagination.page);
      const totalElements = parseInt(this.totalElements)
      const resetPagination = updateSize * currentPage >= totalElements

      this.$emit('change-pagination', { 
        page: resetPagination ? 0 : this.pagination.page, 
        size: updateSize, 
        sortBy:  this.pagination.sortBy,
        sortOrder: this.pagination.sortOrder,
        });
    },
    paginateLeft() {
      this.$emit('change-pagination', { 
        page: parseInt(this.pagination.page) - 1, 
        size: this.pagination.size, 
        sortBy:  this.pagination.sortBy,
        sortOrder: this.pagination.sortOrder
        });
    },
    paginateRight() {
      this.$emit('change-pagination', { 
        page: parseInt(this.pagination.page) + 1, 
        size: this.pagination.size, 
        sortBy:  this.pagination.sortBy,
        sortOrder: this.pagination.sortOrder
        });
    },
  }
}
</script>