<template>
<section>
<form class="hero-body container" @submit.prevent="submitAction()" >
	<div class="box columns is-multiline is-centered">
	<div class="column is-full" v-if="['Coordinator', 'Manager'].includes($parent.login.userType) &&formMode=='Edit'" >
		<div class="level">
			<b-button 
				icon-right="history" 
				size="is-small" 
				type="is-dark" outlined
				tag="router-link"
				:to="`/history/child/${childID}`">
				Change History
			</b-button>
		</div>
	</div>
	<div class="column is-two-thirds">
		
		<div class="container">		
		
		<div class="columns">
			
				<b-field label="Child's name" v-bind='editedField("name")' class="column is-marginless" :class="{'is-half':!formValues.pregnancy}">
					<b-input
						:required="!formValues.pregnancy"
						v-model="formValues.name"
						size="is-medium"
						validation-message="Please fill out this field." 
						pattern="(?!\s*$)((\s*\S*\s*)*)"
						maxlength="50"
						@blur="formValues.name = formValues.name.trim()"
						>
						<!-- regex could be better -->
					</b-input>
				</b-field>
			
				<b-field label="Child's NHI" v-bind='editedField("nhi")' class=" is-half column" :type="NHIwarning(formValues.nhi).type" :message="NHIwarning(formValues.nhi).message" v-if="!formValues.pregnancy">
					<b-input
						:required="!formValues.pregnancy"
						v-model="formValues.nhi"
						size="is-medium"
						>
					</b-input>
				</b-field>
			
		</div>

		<div class="columns">
			
			<b-field label="Caregiver's name" v-bind='editedField("caregiver")' class="column is-marginless is-half" >
				<b-input
					v-model="formValues.caregiver"
					required
					size="is-medium"
					validation-message="Please fill out this field." 
					pattern="(?!\s*$)((\s*\S*\s*)*)"
					maxlength="50"
					@blur="formValues.caregiver= formValues.caregiver.trim()"
					><!-- validation message is based on values before @blur event action -->
					<!-- regex could be better, for example allows double spaces between words -->
				</b-input>
			</b-field>
			
			<b-field label="Caregiver's NHI" v-bind='editedField("caregiver_nhi")' :type="NHIwarning(formValues.caregiver_nhi).type" :message="NHIwarning(formValues.caregiver_nhi).message" class=" is-half column">
				<b-input
					required
					v-model="formValues.caregiver_nhi"
					size="is-medium"
					>
				</b-input>
			</b-field>
			
		</div>

		<b-field label="Address" v-bind='editedField("address")' class="column" >
			<b-input
				type="textarea"
				v-model="formValues.address"
				required
				size="is-medium"
				maxlength="255">
			</b-input>
		</b-field>

		<div class="columns">
			<b-field label="Pregnancy" class="column is-2" custom-class="is-marginless">
				<b-checkbox class="column"
					@input="clearBirthValues()"
					v-model="formValues.pregnancy"
					size="is-large">
				</b-checkbox>
			</b-field>
			<b-field label="Date of Birth" class="column" v-if="!formValues.pregnancy">
				<b-datepicker 
					placeholder="dd/mm/yyyy"
					:date-parser="(date) => NZDate(date, true)"
					:date-formatter="(date) => Intl.DateTimeFormat('en-NZ',{year: 'numeric', month:'numeric', day:'numeric'} ).format(date)"
					v-model="formValues.date_of_birth"
					icon="calendar-day"
					:max-date="new Date()"
					editable>
				</b-datepicker>
			</b-field>
			<!-- Need to make a custom "required" check on datepicker -->
			<b-field label="Expected Due Date" class="column" v-else>
				<b-datepicker
					placeholder="dd/mm/yyyy"
					:date-parser="(date) => NZDate(date, false)"
					:date-formatter="(date) => Intl.DateTimeFormat('en-NZ',{year: 'numeric', month:'numeric', day:'numeric'} ).format(date)"
					v-model="formValues.expected_due_date"
					icon="calendar-day"
					editable>
				</b-datepicker>
			</b-field>
		</div>

		
		<b-loading :is-full-page=false :active="isLoading"></b-loading> 
		</div>
	</div>
			
	<div class="column is-two-thirds">
		<div class="level">
			
		<b-button class="level-item " 
			tag="router-link"
			to="/child"
			type="is-dark" outlined
			size="is-medium"
			icon-left="angle-left">
			Go Back
		</b-button>
		<b-button class="level-item is-marginless "
			:disabled ="!editedDetails" 
			type='is-primary'
			native-type="submit" outlined
			size="is-medium"
			icon-left="angle-right">
			Confirm
		</b-button>
		</div> 
	</div>

	</div>
	
	
</form>
</section>



</template>

<script>
import API from '@/api.js'



export default {
	data() {
	return {
		formValues:{
			name:'',
			nhi:'',
			caregiver:'',
			caregiver_nhi:'',
			expected_due_date:null,
			date_of_birth:null,
			pregnancy:false
		},
		fetchedValues:{
			name:'',
			nhi:'',
			caregiver:'',
			expected_due_date:null,
			date_of_birth:null
		},
		changes:[],
		isLoading: true
	}
	},
	computed:{
	validForm: function(){ 
		let conditionList = {required: false, NHIvalid:false, blank:false} //add condition groups here eg: email input reqs, password input reqs
	
		//required conditions
		if (this.formValues.caregiver !== null && (this.formValues.expected_due_date !== null || this.formValues.date_of_birth !== null) && this.formValues.caregiver_nhi !==null){
			conditionList.required = true
		}

		if(this.formValues.nhi!==this.formValues.caregiver_nhi && this.validNHI(this.formValues.caregiver_nhi) && ((this.formValues.expected_due_date!==null && this.formValues.nhi==null)||(this.formValues.date_of_birth!=null && this.validNHI(this.formValues.nhi)))){
			conditionList.NHIvalid = true
		}

		if(/^\s*$/.test(this.formValues.caregiver)==false && /^\s*$/.test(this.formValues.name)==false){
			conditionList.blank = true
		}

		//if any of the condition groups are not met, return false
		return !Object.values(conditionList).includes(false)
	},
	
	editedDetails: function(){
		if (this.formMode === "Edit"){ //only disable submit button on Edit mode
			let changed = false
			for( let i in this.fetchedValues){
				if(this.fetchedValues[i] !== this.formValues[i]){
					changed=true
					break
				}
			}
			if(this.validForm){
				return changed
			}else{
				return false
			}
		}else if (this.formMode =="Add"){
			return this.validForm
		}else{
			return false
		}
	},
	submitAddress: function(){
		if(this.$route.path.substr(-1)=='/'){
			return this.$route.path.slice(0,-1)
		}
		return this.$route.path
	},
	childID: function(){
		if(this.formMode==='Edit'){
			return this.$route.params.id
		}else{
			return null
		}
	},
	formMode: function(){
		if (this.$route.name === "Child Addition"){
		return "Add"
		} else if(this.$route.name === "Edit Child Profile"){
		return "Edit"
		} else{
		return null //avoiding linter
		}
	},
	

	},
	methods:{
	NZDate(date, restrict){
		let dateformat = date.split('/')
		dateformat = new Date(`${dateformat[2]}-${dateformat[1]}-${dateformat[0]}`)
		if(new Date()<dateformat && restrict){
			return null
		}
		return dateformat
	},
	NHIwarning(nhi){
		let warning={message:'',type:''}
		if(nhi==''){
			return warning
		}
		if(this.validNHI(nhi)==false && nhi!==''){
			warning.message="Invalid NHI"
			warning.type='is-danger'
		}
		
		if(this.formValues.caregiver_nhi==this.formValues.nhi){
			warning.message="NHI must be different"
			warning.type="is-danger"
		}
		return warning
	},
	clearBirthValues(){
		if(this.formValues.pregnancy==true){
			this.formValues.date_of_birth =  null
			this.formValues.nhi = null
		}
	},
  NHICharToNum(charcode) {//letters are given a value between 1-24
    let numval = charcode-64;
    if (numval>9){ //shift down for letters greater than I
      numval-=1
    }
    if (numval>14){ //shift down again for greater than O
      numval-=1
    }
    return numval;
  },
	validNHI(NHI) {
    let mod;
    let format1 = RegExp('^((?=[^IO]+$)[A-Z]){3}([0-9]){4}$');
    //basic check: no I or O, 3 letters, 4 numbers (AAANNNN)
    
    let format2 = RegExp('^((?=[^IO]+$)[A-Z]){3}([0-9]){2}((?=[^IO]+$)[A-Z]){2}$');
    //basic check: no I or O, 3 letters, 2 numbers, 2 letters (AAANNAA)
    
    // detect which format
    if ( format1.test(NHI) ) {
      mod = 11;
    } else if ( format2.test(NHI) ) {
      mod = 24;
    } else {
      return false;
    }
    
    let checksum = 0
    for ( let x = 0; x < 6; x++) {
      let charcode = NHI.charCodeAt(x);
      if (charcode<65) { //For numbers
        checksum += Number(NHI[x])*(7-x)
      } else { //for letters
        let numval = this.NHICharToNum(charcode);
        checksum+=numval*(7-x)
      }
    }
    
    let remander = checksum % mod;
    let checkdigit = mod - remander;
    // console.log('checksum:'+checksum);
    // console.log('remander:'+remander);
    // console.log('checkdidget:'+checkdigit);

    if (mod == 24) {// new mod check
      return checkdigit ==this.NHICharToNum(NHI.charCodeAt(6));
    }
    if (checksum==0) {
      return false;
    }
    if (checkdigit ==10) {
      checkdigit = 0;
    }
    return checkdigit == NHI[6];
  },
	async submitAction(){
		if (!this.validForm){
			this.isLoading = true
			API.endSession(false).then(response=>{
				this.$router.push('/login')
				return response //avoiding linter
			})
		}else if(this.formMode === "Edit"){ //Edit User Success
			this.formValues.changes=this.changes
			this.isLoading = true
			API.updateChild(this.childID, this.formValues).then(response =>{ 
				this.isLoading = false
				if (response !== false){
					if(response.error==undefined){
						this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
						this.formValues= response //turns the reference value (object) into a primitive value (string) and then changes back to required type (object)
						this.$buefy.dialog.alert({ 
							title: 'Child Updated!',
							message: `The changes to ${response.name} have been saved.`,
							type: 'is-success',
							confirmText: 'Go Back',
							onConfirm: () => this.$router.push({ name: 'Caseload'}),
							cancelText: 'Continue Editing',
							canCancel: true,
							hasIcon: true,
							icon: 'user-edit',
							size:'is-medium'
						})
					}else if(response.error!==undefined){
						this.$buefy.dialog.alert({ 
							title: 'Duplicate Child NHI',
							message: `The NHI of the child must be unique. `,
							type: 'is-warning',
							hasIcon: true,
							icon: 'user-times',
							size:'is-medium'
						})
						
					}
					
					
				}else{
					//alert DB and end Session
					API.endSession(false).then(response=>{
						this.$router.push('/login')
						return response //avoiding linter
					})
				}
			})
		}else if (this.formMode === "Add"){ //Add Child
			this.isLoading = true
			
			
			API.insertChild(this.formValues).then(response=>{
				this.isLoading = false
				if (response.id !==undefined){
					

					this.$buefy.dialog.alert({ 
						title: 'Child added!',
						message: `You can make edits to these details or create submissions for the child <b><a href="/child/${response.id}" target="_blank"></b>${response.name} here.</a> `,
						type: 'is-success',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({ name: 'Caseload'}),
						cancelText: 'Add another Child',
						onCancel: () => { for (let key in this.formValues) { this.formValues[key] = null }},
						canCancel: true,
						hasIcon: true,
						icon: 'user-plus',
						size:'is-medium'
					})


				}else if(response.error!==undefined){
					this.$buefy.dialog.alert({ 
						title: 'Duplicate Child NHI',                                 
						message: `The NHI of the child must be unique. `,
						type: 'is-warning',
						hasIcon: true,
						icon: 'user-times',
						size:'is-medium'
					})
				}else{
					//alert DB and end Session
					API.endSession(false).then(response=>{
						this.$router.push('/login')
						return response //avoiding linter
					})
				}
			})
		}
		
	},
	editedField(detail){
		if(this.formMode === "Edit" ){
			if (this.fetchedValues[detail] !== this.formValues[detail]){
				if(!this.changes.includes(detail)){
					this.changes.push(detail)
				}
				if(detail=='expected_due_date'||detail =='date_of_birth'){
					return {type:"is-warning", message:`Changed from ${this.fetchedValues[detail]}`}
				}
				return {type:"is-warning", message:`Changed from ${this.fetchedValues[detail]}`}
			} 
		}
	}
	},
	beforeRouteUpdate (to, from, next) { //when switching between /child/x and /child/y
		this.isLoading=true;
		console.log(to, from, next)
		if (this.formMode === "Edit"){
			API.getChild(`/child/${to.params.id}`).then(response =>{ //view is rendered for edit mode
				console.log("response")
				if(response == false){ //When inactive/non-existing user
					this.$buefy.dialog.alert({ 
						title: 'Cannot Access Child',                                 
						message: `There is no accessible Child with this ID.`,
						type: 'is-warning',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({ name: 'Caseload'}),
						hasIcon: true,
						icon: 'user-times',
						size:'is-medium'
					})
				}else{
					console.log("response gotten")
					if(response.date_of_birth==null){
						response.pregnancy=true
					}
					response.date_of_birth = new Date(Date.parse(response.date_of_birth))
					response.expected_due_date = new Date(Date.parse(response.expected_due_date))
					this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
					this.formValues= response 
					this.isLoading = false	
					next()
				}
			})
		}
		
		
	},

	created(){
		if (this.formMode === "Edit"){
			API.getChild(`/child/${this.childID}`).then(response =>{ //view is rendered for edit mode
				console.log("response")
				if(response == false){ //When inactive/non-existing user
					this.$buefy.dialog.alert({ 
						title: 'Child does not Exist', 
						message: `There is no accessible Child with this ID.`,
						type: 'is-warning',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({ name: 'Caseload'}),
						hasIcon: true,
						icon: 'times-circle',
						size:'is-medium'
					})
				}else{
					console.log("response gotten")
					if(response.date_of_birth==null){
						response.pregnancy=true
					}
					response.date_of_birth = new Date(Date.parse(response.date_of_birth))
					response.expected_due_date = new Date(Date.parse(response.expected_due_date))
					this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
					this.formValues= response 
					this.isLoading = false	
				}
			})
		}
	},
	async mounted(){
		if (this.formMode === "Add"){
			setTimeout(() => { //view is rendered add mode
				this.isLoading = false 
			}, 0)
		}
	},

		
}
</script>
