<template> 
	<div>
		<!-- CHANGE TO SHOW DIFFERENT STAGE DEPENDING ON USERTYPE -->
		<component :is="SubView" :state="stateResp" :fetchedValues="fetchedValues" v-if="stateResp" v-on:savedSub="updateOnSave"/>
		<!-- State can be completed, rejected, in progress, otherprov -->
		<div v-else> 
			
			<section class="columns is-centered">
				<div class="hero-body column is-half" >
					<b-notification class="box " :closable="false" type="is-white">
						<section class="hero is-large">
							<div class="hero-body">
								
							</div>
						</section>
						
						<b-loading 
							:is-full-page="false"
							:active.sync="isLoading">
						</b-loading>
					</b-notification>
				</div>
			</section>
		</div>
	
		
	</div>

</template>

<script>
	
	import API from '@/api.js'


	export default {
		data(){
			return {
				stateResp:false,
				fetchedValues:{
					stage:null
				},
				isLoading:true,
				isSaved:false
			}
		},
		computed:{
			login(){
				return this.$root.$children[0].login
			},
			SubView () {
				// Need to account for saved but not sent New Submission
				if(this.fetchedValues.stage=='ETER Approval'){
					return () => import('./Stage3_Coordinator_Approve')
				} else if(this.fetchedValues.stage=='Review'){
					return () => import('./Stage2_Manager_Review')
				} else if(this.fetchedValues.stage=='Purchasing'){
					return () => import('./Stage4_Accounts_Purchasing')
				} else if(this.fetchedValues.stage=='Follow-Up'){
					return () => import('./Stage5_Staff_Feedback')
				} else if(this.fetchedValues.stage=='Referral'){
					return () => import('./Stage1_Staff_Initial')
				}else{
					return null //avoiding linter
				}
				
			},
			submitAddress: function(){
				if(this.$route.path.substr(-1)=='/'){
					return this.$route.path.slice(0,-1)
				}
				return this.$route.path
			},
		},
		created(){//this will check if the user is able to view/edit the submission - what kind of modal to display if any.
			this.stateResp=true
			API.getSubState(this.submitAddress).then(response=>{
				console.log(response)
				this.stateResp=response.state
				this.fetchedValues=response.values
				if(this.stateResp=='Invalid'){
					this.$buefy.dialog.alert({ 
						title: 'Cannot Access Submission',                                 
						message: `There is no accessible Submission with this ID.`,
						type: 'is-danger',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({path:'/home'}),
						hasIcon: true,
						icon: 'times-circle',
						size:'is-medium'
					})
				}else if(this.stateResp =='DisallowProgress' ){
					this.$buefy.dialog.alert({ 
						title: 'Submission In Progress',                                 
						message: `You cannot access it until it is resolved, or it reaches a stage where you can contribute.`,
						type: 'is-warning',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({path:'/home'}),
						hasIcon: true,
						icon: 'pen',
						size:'is-medium'
					})
				}else if(this.stateResp =='DisallowOtherProv'){
					this.$buefy.dialog.alert({ 
						title: 'Unauthorised',                                 
						message: `This submission is managed by a different provider`,
						type: 'is-danger',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({path:'/home'}),
						hasIcon: true,
						icon: 'user-times',
						size:'is-medium'
					})
				}
			})
		},
		methods:{
			updateOnSave(response){
				this.stateResp=response.state
				this.fetchedValues=response.values
				this.isSaved=true
			
			}
		}
	
	}
</script>
