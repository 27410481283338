<template>
<section class="columns is-centered">
<form class="hero-body column container" @submit.prevent="submitAction()">
	<div class="box columns is-centered is-multiline">
		<div class="column is-full" v-if="targetChild!=undefined">
			<div class="level">
				<div class="level-left">
					<b-message type="is-primary is-marginless " 
						:closable="false"
						:active.sync="isMessage" 
						:auto-close="true"	
						>
						Your form values are saved
					</b-message>
				</div>
				<b-field :message="saveWarning.message" :type="saveWarning.type">
					<b-button class="is-pulled-right level-right" id="savebutton" outlined
						:disabled="saveWarning.message!=''"
						@click="saveAction()"
						type="is-primary"
						size="is-medium" 
						icon-left="save">
						Save Progress
					</b-button>
				</b-field>
			</div>
		</div>
		<div class="column columns is-two-thirds is-multiline is-vcentered">
		
		<div class="column is-full" v-if="targetChild ==undefined">
			<div class="level">
			<b-button class="level-item title " 
				tag="router-link"
				to="/child" 
				type='is-primary'
				size="is-large"
				icon-left="plus">
				Select Child for Submission
			</b-button>
			</div>
		</div>
		<div v-else class=" column columns">
			<div  v-if="targetChild.nhi" class="is-half column">
				<router-link :to="{path:`/child/${targetChild.id}`}" class="is-size-3 has-text-weight-semibold"> 
					{{displayName}}<br>
					({{targetChild.nhi}})
				</router-link>
				<h2 class="subtitle is-size-4">
					{{targetChild.caregiver}}
				</h2>
			</div>
			<div  v-else class="is-half column">
				<router-link :to="{path:`/child/${targetChild.id}`}" class="is-size-3 has-text-weight-semibold"> 
					{{displayName}} 
				</router-link>
				<h2 class="subtitle is-size-4">
					{{targetChild.caregiver}}
				</h2>
			</div>
		
			<h2 class="box is-size-3 has-text-centered has-text-weight-semibold column is-half">
				Funding Available: <br>
				{{new Intl.NumberFormat('en-NZ', { style: 'currency', currency: 'NZD' }).format(targetChild.avail_funds)}}
			</h2>
		</div>
		
		<div class="column is-full">
			<div class="level is-marginless">
				<div class="level-left">
					<b-field label="Provider Organisation" class="" v-if="$parent.login.userType==='Coordinator'">
						<b-select v-model="inputs.provider_id" placeholder="Select a Provider" required expanded size="is-medium" :disabled="targetChild==undefined">
							<option v-for="prov of provOptions" v-bind:key="prov.id" v-bind:value="prov.id">{{prov.organisation}}</option>
						</b-select>
					</b-field>
				</div>
				<div class="level-right"
					@mouseover="isHovering = true"
					@mouseleave="isHovering = false">
					
						<b-upload v-if="targetChild!==undefined"
							v-model="inputs.attachments"
							accept="image/png,image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
							multiple
							:disabled="targetChild==undefined">
							<b-field message="Only attach .png, .jpg, .docx, .doc or .pdf" class="is-marginless">
								<a class="button is-medium is-dark has-text-centered is-pulled-right marglist" 
								:class="{'is-outlined': !isHovering}">
									<b-icon icon="paperclip"></b-icon>
									<span>Attach Files</span>
								</a>
							</b-field>
						</b-upload>
						
				</div>
				
			</div>
			<div class="column is-full"> 
				<div v-if="isSaved" class="">
					<b-taglist attached v-for="(file, index) in fetchedValues.attachments" :key="index + '-attached'" class="is-pulled-right marglist botmargin">
						<div class="marglistoverflow">
						<b-tag type="is-light cursorPointer" >
							<div @click="downloadAttachment(file.filename, file.id)">
								<b-icon class="button is-light is-small" icon="download" />
							</div>
						</b-tag>
						<b-tag type="is-primary" class="is-full" :closable="state=='Standard' && $parent.login.userType!=='Staff'" @close="deleteAttachment(index, file.filename)">
							{{file.filename}}
						</b-tag>
						</div>
					</b-taglist>
					<div/><!--placeholder-->
				</div>
				<div class= "">
					<span v-for="(file, index) in inputs.attachments"
						:key="index +'-attaching'"
						class="tag is-primary marglist is-pulled-right botmargin">
						{{file.name}}
						<button class="delete is-small testright"
							type="button"
							@click="deleteAttaching(index)">
						</button>
					</span>
				</div>
				<!-- <div v-for="(file,index) in attaching" :key="index + 'attaching'" class="is-full is-pulled-right margbot marglist" >
					<b-tag  type="is-primary" closable @close="deleteAttaching(index)">
						{{file.name}}
					</b-tag>
				</div> -->
			</div>
			
		</div>
		<b-message :type="ViewOnly.type" :title="ViewOnly.title"  v-if="state!='Standard' && id!==null" :closable="false" class="is-flex">
			{{ViewOnly.message}} 
		</b-message>
		<div class="column is-full columns is-size-5">
			<div class="column is-2" v-if="fetchedValues">
				Referral: <br>
					{{fetchedValues.referrer}}
			</div>
		</div>
		<div class="column columns is-full">	
			<div class="column is-half">
				<b-field label="Request Description" >
					<b-input
						:disabled="targetChild==undefined || (state!='Standard' && id!==null)"
						v-model="inputs.description"
						required
						type="textarea"
						size="is-medium">
					</b-input>
				</b-field>
			</div>
			<div class="column is-half" >
				<b-field>
					<template slot="label" class="is-marginless">
						<h1 class=" label ">
							Categorise Request
						</h1>
					</template>
					<b-autocomplete
						:disabled="targetChild==undefined || (state!='Standard' && id!==null)"
						placeholder="Search and Select a Category"
						size="is-medium"
						icon="plus"
						v-model="categorySearch"
						:open-on-focus="true"
						:clear-on-select="true"
						field="title"
						:data="categoryFiltered"
						@select="option => inputs.selectedCategory=option">
						<template slot-scope="props">
							<div type="is-primary">
								<b>{{ props.option.title }}</b>
								<small>
										{{ descPreview(props.option.description) }}
								</small>
							</div>
						</template>
					</b-autocomplete>
				</b-field>
				<b-field horizontal custom-class="is-medium level-left" class="level">
					<template slot="label">
						<p style="margin-right: 0.5em;">{{inputs.selectedCategory.title}}</p>
						<b-tooltip type="is-light" :label="inputs.selectedCategory.description" multilined size="is-medium" position="is-right">
							<b-icon size="is-small" icon="info-circle" v-if="inputs.selectedCategory.description!=null"></b-icon>
						</b-tooltip>
					</template>
					<b-button outlined class="is-pulled-right"
						v-if="(state=='Standard' && inputs.selectedCategory.id!=null) || (id==null && targetChild!==undefined && inputs.selectedCategory.id!=null)"
						@click="inputs.selectedCategory= {id:null,title:null,description:null}"
						type="is-danger" 
						icon-right="times-circle">
					</b-button>
					<div v-else />
				</b-field>
			</div>
		</div>

		<div class="columns">
			<div class="column">
				
				<b-field label="Funding Requested"  message="Cannot exceed the Funding Available.">
				
				<b-input
					:disabled="targetChild==undefined || (state!='Standard' && id!==null)"
					v-model="inputs.funding_req"
					required
					placeholder="###.##"
					icon ="dollar-sign"
					size="is-medium"> 
				</b-input>
				
				</b-field>
				

				<!-- Should this be a required field?-->
				<b-field label="Family Contribution"  message="Do not include $ sign. Only numbers and decimal point.">
				<b-input
					:disabled="targetChild==undefined || (state!='Standard' && id!==null)"
					v-model="inputs.contributions"
					placeholder="###.##"
					required  
					icon ="dollar-sign"
					size="is-medium">
				</b-input>
				</b-field>
				
				<b-field label="Expected Cost" >
				<b-input
					:disabled="targetChild==undefined || (state!='Standard' && id!==null)"
					v-model="expectedCost"
					readonly
					size="is-medium">
				</b-input>
				
				</b-field>

			</div>
		
			<div class="column">
				<b-field class="columns is-multiline ">
					<template slot="label" class="is-marginless">
						<h1 class=" label rm-bot-padding">
							Risk Criteria
						</h1>
					</template>
					<!-- Funny padding issue, just cosmetic -->
					<b-message class=" column is-full" :type="criteriaMessage.type" v-if="state=='Standard' || (id==null && targetChild!==undefined)">
						{{criteriaMessage.text}}
					</b-message>
					<b-field class="column is-full is-marginless rm-top-padding">
						<b-autocomplete
							:disabled="targetChild==undefined || (state!='Standard' && id!==null)"
							placeholder="Search and Select Risk Criteria..."
							size="is-medium"
							icon="plus"
							v-model="criteriaSearch"
							:clear-on-select="true"
							:open-on-focus="true"
							field="title"
							:data="riskCriteriaFiltered"
							@select="option => selectCriteria(option)">
							<template slot-scope="props">
								<div type="is-primary">
									<b>{{ props.option.title }}</b>
									<small>
											{{ descPreview(props.option.description) }}
									</small>
								</div>
							</template>
						</b-autocomplete>
					</b-field>
					
					<div  class="column is-full rm-top-padding">
						<b-field horizontal custom-class="is-medium level-left" class="level" v-for="criteria of inputs.selectedCriteria" v-bind:key="criteria.id">
							<template slot="label">
								<p style="margin-right: 0.5em;">{{criteria.title}}</p>
								<b-tooltip type="is-light" :label="criteria.description" multilined size="is-medium" position="is-right">
									<b-icon size="is-small" icon="info-circle" v-if="criteria.description!=null"></b-icon>
								</b-tooltip>
							</template>
							<b-button outlined class="is-pulled-right"
								v-if="state=='Standard' || (id==null && targetChild!==undefined)"
								@click="deselectCriteria(criteria)"
								type="is-danger" 
								icon-right="times-circle">
							</b-button>
							<div v-else />
						</b-field>
						
					</div>
				</b-field>
			</div>
		</div>
		</div>
		<div class="column is-two-thirds">
		<div class="level">
			<b-button class="level-item "
			@click="$router.go(-1)"
			type="is-dark" outlined
			size="is-medium"
			icon-left="angle-left">
			Go Back
			</b-button>
			<b-button class="level-item "
			type='is-primary'
			native-type="submit" outlined
			size="is-medium"
			:disabled="!allowSubmit"
			icon-left="angle-right">
			Send Request
			</b-button>
		</div> 
		</div>  
	</div>
</form>

</section>



</template>

<script>
import API from '@/api.js';
export default {
	data() {
		return {
			inputs:{
				description: null,
				funding_req: null,
				contributions: null,
				provider_id: null,
				attachments: [],
				selectedCriteria:[],
				selectedCategory:{id:null, title:null, description:null},
			},
			categories:[],
			categorySearch:'',
			riskCriteria:[],
			criteriaSearch:'',
			minimum:null,
			provOptions: [],
			isHovering: false,
			isMessage: false,
			isSaved: false,
			id: null
		}
	},
	props: ['targetChild', 'state', 'fetchedValues'],
	created() {
		if (this.fetchedValues) { //watch out between the differences of fetchedValues here, vs other Submission components
			this.targetChild = {name: this.fetchedValues.childname, 
								caregiver:this.fetchedValues.caregiver, 
								date_of_birth: this.fetchedValues.date_of_birth, 
								edd: this.fetchedValues.expected_dude_date, 
								nhi:this.fetchedValues.nhi, 
								avail_funds:this.fetchedValues.avail_funds, 
								id:this.fetchedValues.child_id
								};
			this.inputs = {attachments:[], 
							description:this.fetchedValues.description, 
							funding_req:this.fetchedValues.req_funding, 
							contributions:this.fetchedValues.contributions, 
							provider_id:this.fetchedValues.provider_id,
							selectedCriteria:this.fetchedValues.risk_criteria,
							selectedCategory:{id:this.fetchedValues.category_id, title:this.fetchedValues.categoryname, description:this.fetchedValues.categorydesc},
							};
			this.isSaved = true;
			this.id = this.fetchedValues.id;
		}
		API.getRiskCriteriaOptions().then(response =>{
			
			this.riskCriteria = response.list;
			this.minimum = response.minimum;

		});

		API.getCategoryOptions().then(response=>{
			this.categories= response
		});
		
		if (this.$parent.login.userType==='Coordinator') {
			API.getProvOpt().then(response=>{
				this.provOptions=response.content
			});
		}
		if(this.$parent.isSaved==true){
			this.isMessage=true
		}
	},
	computed:{
		riskCriteriaFiltered() {
			let selectedID = [];
			for (let i of this.inputs.selectedCriteria) {
				selectedID.push(i.id);
			}
			return this.riskCriteria.filter((option) => {
				return option.title.toString().toLowerCase().indexOf(this.criteriaSearch.toLowerCase()) >= 0 && !selectedID.includes(option.id);
			});
		},
		criteriaMessage() {
			let message = {text: `${this.inputs.selectedCriteria.length} out of ${this.minimum} minimum required.`, type:'is-info'};
			if (this.inputs.selectedCriteria.length>=this.minimum) {
				message.type='is-success';
			}
			return message;
		},
		categoryFiltered(){
			return this.categories.filter((option) => {
				return option.title
					.toString()
					.toLowerCase()
					.indexOf(this.categorySearch.toLowerCase()) >= 0
			})
		},
		expectedCost:function() {
			return new Intl.NumberFormat('en-NZ', { style: 'currency', currency: 'NZD' }).format(Number(this.inputs.funding_req) + Number(this.inputs.contributions)) 
		},
		saveWarning:function() {
			let message = '';
			let type = '';
			if (this.$parent.login.userType=='Coordinator' && this.inputs.provider_id==null) {
				type = 'is-warning';
				message ='Provider selection required';
			}
			if (this.expectedCost=="$0.00" || 
			Number(this.inputs.funding_req) <= 0 || 
			Number(this.inputs.contributions) < 0 || 
			this.expectedCost =="$NaN" || 
			(Number(this.inputs.funding_req)>this.targetChild.avail_funds && !this.isSaved)) {
				type = 'is-warning';
				message ='Invalid funding values';
			}
			if (this.isSaved) {//prevent errors accessing fetchedValues
				if (Number(this.inputs.funding_req) > this.targetChild.avail_funds+this.fetchedValues.req_funding && this.isSaved) {
					type = 'is-warning';
					message ='Invalid funding values';
				}
			}
			if (this.inputs.description == null) {
				type = "is-warning";
				message = "Description required";
			}
			return { message: message, type: type};
		},
		backPath: function(){
			if (this.targetChild==undefined) {
				return "/child";
			}else{
				return `/child/${this.targetChild.id}`;
			}
		},
		allowSubmit:function() {
			if (this.expectedCost=="$0.00" || 
			Number(this.inputs.funding_req)<=0 || 
			Number(this.inputs.contributions)<0 || 
			this.expectedCost =="$NaN" || 
			this.inputs.description == null || 
			(Number(this.inputs.funding_req)>this.targetChild.avail_funds && !this.isSaved) ||
			(this.$parent.login.userType=='Coordinator' && this.inputs.provider_id==null)) {
				return false;
			} else if (this.isSaved) {
				if (this.isSaved && Number(this.inputs.funding_req)>this.targetChild.avail_funds+this.fetchedValues.req_funding ) {
					return false;
				}
				return true;
			} else {
				return true;
			}
		},
		ViewOnly() {
			if (this.state=='AllowOtherProv') {
				return {type:"is-warning", message:"This submission is being managed by a different Provider Organisation", title:'View Only'};
			} else if (this.state=='Rejected') {
				if (this.fetchedValues.rejection_notes!=null) {
					return {type:"is-danger", message:`Rejection Notes: ${this.fetchedValues.rejection_notes} `, title:"Rejected"};
				} else {
					return {type:"is-danger", message:'This submission has been rejected and closed.', title:"Rejected"};
				}
			} else {
				return {type:"is-warning", message:"This submission is not yet finished being reviewed. ", title:"View Only"};
			}
		},
		displayName() {
			if (this.targetChild.date_of_birth == null) {
				if (this.targetChild.name==null) {
					return "Pregnancy";
				} else {
					return this.targetChild.name + " (Pregnancy)";
				}
			} else {
				return this.targetChild.name;
			}
		},
		submitAddress: function() {
			if (this.$route.path.substr(-1)=='/') {
				return this.$route.path.slice(0,-1);
			}
			if (this.id!==null) {
				return `/sub/${this.id}`;
			}
			return this.$route.path;
		}
	},
	watch: {
		isMessage: function(){
			if (this.isMessage==false && this.$route.params.id==undefined) {
				this.$router.push(this.submitAddress);
			}
		}
	},
	methods: {
		descPreview:function(description){
			if(description==null){
				return ''
			}
			return description.length>50? description.substring(0,50)+'...':description 
		},
		selectCriteria(option) {
			if (!(this.inputs.selectedCriteria.includes(option)||option==null)) {
				this.inputs.selectedCriteria.push(option);
			}
		},
		deselectCriteria(option) {
			this.inputs.selectedCriteria = this.inputs.selectedCriteria.filter(function(ele) {
				return ele != option;
			});
		},
		prepareSend(acceptance) {
			let provider = this.$parent.login.provider_id;
			if (provider==null) {
				provider=this.inputs.provider_id;
			}
			let selectedID = [];
			for (let i of this.inputs.selectedCriteria) {
				selectedID.push(i.id);
			}
			let formValues = {
				description:this.inputs.description, 
				req_funding: Number(this.inputs.funding_req), 
				contributions:Number(this.inputs.contributions), 
				risk_criteria:selectedID,
				category_id:this.inputs.selectedCategory.id,
				child_id: this.targetChild.id, 
				provider_id:provider, 
				stage:'Referral'};
			let formData = new FormData();
			if (acceptance) {
				formData.append('formValues', JSON.stringify(formValues));
				for (let i of this.inputs.attachments) {
					formData.append('file', i);
				}
			}//avoiding linter
			return formData;
		},
		submitAction() {
			let formData = this.prepareSend(true);
			formData.append('advance', true);
			if (this.isSaved) {
				// update existing submission, setting 'advance' to true
				API.updateSubmission(this.submitAddress+'/submit', formData).then(response =>{ 
					if (response.err==undefined) {
						this.$buefy.dialog.alert( {
							title: 'Submission Created!',
							message: `The request for funding has been sent for review.`,
							type: 'is-success',
							size:'is-medium',
							confirmText: 'Back to Home',
							onConfirm: () => this.$router.go(-1),
							canCancel: true,
							cancelText: 'Back to Form',
							onCancel: () => this.$router.go(0),
						});
					}else{
						this.$buefy.dialog.alert({ 
							title: 'Submission already advanced',
							message: response.err,
							type: 'is-warning',
							size:'is-medium',
							confirmText: 'Back to Home',
							onConfirm: () => this.$router.go(-1),
							canCancel:true,
							cancelText: 'Try Again',
							onCancel: () => this.$router.go(0),
						});
					}
				});
			} else { //add new submission of stage 'Review'
				API.insertSubmission(formData).then(response =>{ //Works :)
					if (response==false) {
						API.endSession(true).then(response=>{
							this.$router.push('/login');
							return response;
						});
					} else if(response.id!=undefined){
						this.$router.go(-1);
						this.$buefy.notification.open({
              message: 'Submission Created!',
              type: 'is-success',
							position: 'is-bottom'
            })
					}else{
						this.$buefy.dialog.alert({ 
							title: 'Could not Create Submission',
							message: response.err +'<b>' + new Intl.NumberFormat('en-NZ', { style: 'currency', currency: 'NZD' }).format(response.avail_funds) + '</b>',
							type: 'is-danger',
							size:'is-medium',
							hasIcon: true,
							icon: 'times-circle',
							confirmText: 'Try again',
							onConfirm: () => this.targetChild.avail_funds=response.avail_funds,
							canCancel: true,
							cancelText: 'Back to Home',
							onCancel: () => this.$router.go(-1),
						});
					}
				});
			}
		},
		saveAction() {
			let formData = this.prepareSend(true);
			formData.append('advance', false);
			if (this.isSaved) {
			
				API.saveSubmission(this.submitAddress+'/submit', formData).then(response =>{
					if (response.err==undefined) {
						this.$emit('savedSub', response)
						this.isMessage = true;
						let difference = this.fetchedValues.req_funding-this.inputs.funding_req;
						this.fetchedValues.req_funding = this.inputs.funding_req;
						this.targetChild.avail_funds = this.targetChild.avail_funds+difference;
					}else{
						this.$buefy.dialog.alert({ 
							title: 'Submission already advanced',
							message: response.err,
							type: 'is-warning',
							size:'is-medium',
							confirmText: 'Back to Home',
							onConfirm: () => this.$router.go(-1),
							canCancel:true,
							cancelText: 'Try Again',
							onCancel: () => this.$router.go(0),
						});
					}
				});
				//update existing submission, set advance to false
			} else {
				// create new submission of stage 'Referral'
				API.insertSubmission(formData).then(response =>{
					if (response === false) {
						API.endSession(true).then(response=>{
							this.$router.push('/login');
							return response;
						});	
					} else if(response.id!=undefined){
						this.id = response.id;
						this.isMessage = true;
					}else{
						this.$buefy.dialog.alert({ 
							title: 'Could not Save Submission',
							message: response.err +'<b>' + new Intl.NumberFormat('en-NZ', { style: 'currency', currency: 'NZD' }).format(response.avail_funds) + '</b>',
							type: 'is-danger',
							size:'is-medium',
							hasIcon: true,
							icon: 'times-circle',
							confirmText: 'Try again',
							onConfirm: () => this.targetChild.avail_funds=response.avail_funds,
							canCancel: true,
							cancelText: 'Back to Home',
							onCancel: () => this.$router.go(-1),
						});
					}
				});
			}
		},
		deleteAttachment(index, filename) { //delete existing attachment (already on server)
			this.$buefy.dialog.alert({ 
				title: 'Remove Attachment?',
				message: `This will delete ${filename} from this submission.`,
				type: 'is-danger',
				canCancel:true,
				confirmText: 'Delete',
				onConfirm: () => { 
					this.fetchedValues.attachments.splice(index, 1);
					API.deleteAttachment(filename, this.submitAddress);
				}, 
				size:'is-medium'
			});
		},
		deleteAttaching(index) {
			this.inputs.attachments.splice(index, 1);
		},
		downloadAttachment(filename, fileId){
			API.getAttachmentDownload(filename, fileId);//make download button loading??
		}
	}
}
</script>
<style scoped>
	.topfloat{
		margin-top: auto
	}
	.botmargin{
		margin-bottom: 1em
	}
	.testright{
		margin-left: auto	
	}
	.marglist{ /*forcing additional attachments to be per line*/ 
		margin-left:100%;
	}
	.cursorPointer{
		cursor: pointer;
	}
	.marglistoverflow{
		display:flex;
	}
	#savebutton{
		margin: 0.5em;
	}
</style>