<template>
  <section class="container hero-body">
    <div class="container box">
      <div class="level" style="margin-bottom: 1.5rem">
        <div class="level-left">
          <b-input 
              v-model="filters.searchInput" 
              placeholder="Search..." 
              type="search"
              icon="search"
              @input="filters.searchInput = $event"
            />
        </div>
        <div class="level-right">
          <b-button
            tag="router-link"
            to="/child/add"
            type="is-primary" 
            outlined
            icon-left="plus"
          >
            Add Child
          </b-button>
        </div>
      </div>
      <ListTable  
        :isLoading="isLoading" 

        :columns="columns" 
        :rows="caseload" 
        rowRoute="Child Profile" 

        :pagination="pagination"
        :total-elements="totalElements"
        @change-pagination="pagination = $event"
      />
    </div>
  </section>
</template>

<script>
import ListTable from '../../components/layout/ListTable.vue'
import API from '@/api.js'
export default {
  components: { ListTable },
  data () {
    return {
      columns: [
        { field: 'id', label: '#', width: '1', sortable:true },
        { field: 'nhi', label: 'Child NHI', width: '150', sortable:true },
        { field: 'name', label: 'Child Name', width: '200', sortable:true },
        { field: 'caregiver', label: 'Caregiver Name', width: '200', sortable:true },
        { field: 'caregiver_nhi', label: 'Caregiver NHI', width: '200', sortable:true },
        { field: 'available', label: 'Available Funds', width: '150', numeric:true, sortable: false }
      ],
    
    pagination: { // Pagination options
      page: 0,
      size: 10,
      sortBy: 'name',
      sortOrder: 'asc',
    },
    totalElements: 0,

    filters: {
        searchInput: ''
      },

    caseload: [ //template value prevents "Nothing Here" table view before async completed
      {
        id: null,
        nhi: null,
        name:null,
        caregiver:null,
        caregiver_nhi:null
      } 
    ],
    isLoading:null
    }
  },
  watch: { // If change in table data options are detected, update the URL and fetch the data
    pagination: {
      deep: true,
      handler() {
        this.updateUrl();
        this.getChildren();
      }
    },
    filters: {
      deep: true,
      handler() {
        this.updateUrl();
        this.getChildren();
      }
    },
  },
  methods:{
    getChildren() {
      const payload = this.getPayload();
      API.getCaseload(payload).then( response => {
        this.loading = false;
        this.caseload = response.content;
        this.totalElements = response.totalElements;

        this.pagination.page = response.page
        this.pagination.size = response.size
      })
    },
    getPayload () { // Constructs the request payload object
      return {
        // Pagination options
        page: this.pagination.page,
        size: this.pagination.size,
        sortBy: this.pagination.sortBy,
        sortOrder: this.pagination.sortOrder,

        // Filter options
        search: this.filters.searchInput,
      }
    },
    initializeUrl () { // Fetches URL query parameters to apply to current filters
      const initialQuery = this.$route.query; // Reads current URL
      let query = {
        search: initialQuery.search ?? '',
        page: initialQuery.page ?? this.pagination.page.toString(),
        size: initialQuery.size ?? this.pagination.size,
        sortBy: initialQuery.sortBy ?? this.pagination.sortBy,
        sortOrder: initialQuery.sortOrder ?? this.pagination.sortOrder,
      }
      query = Object.fromEntries( // Filters out invalid query parameters so they don't get appended to the final URL
        Object.entries(query).filter(([key, value]) => {
          return !! value;
        })
      )

      // Applying query parameters
      // Mandatory pagination controls options
      this.pagination = { 
        page: query.page,
        size: query.size,
        sortBy: query.sortBy,
        sortOrder: query.sortOrder,
      }
      if (query.search) {
        this.filters.searchInput = query.search
      }

      this.$router.replace({ name: 'Caseload', query }).catch(() => {});
    },
    updateUrl () { //Replace the URL with query parameters that describe the state of the page
      let query = {
        search: this.filters.searchInput,

        page: this.pagination.page.toString(),
        size: this.pagination.size,
        sortBy: this.pagination.sortBy ? this.pagination.sortBy : undefined,
        sortOrder: this.pagination.sortOrder ? this.pagination.sortOrder : undefined,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => {
          return !! value;
        })
      )
      // Applying page state
      const queryString = []
      // Maandatory pagination controls options
      queryString.push(`page=${query.page}`);
      queryString.push(`size=${query.size}`);
      queryString.push(`sortBy=${query.sortBy}`);
      queryString.push(`sortOrder=${query.sortOrder}`);
      
      // Optional filter options
      if (query.search) {
        queryString.push(`search=${query.search}`);
      }

      history.pushState({}, document.title, `child?${queryString.join('&')}`)
    },
  },
  created() {
    this.initializeUrl();
  }	
}
</script>
