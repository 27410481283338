<template>
<section>
<form class="hero-body container" @submit.prevent="submitAction()" >
	<div class="box columns is-multiline is-centered">
	<div class="column is-two-thirds">
		<div class="container">
		<h1 class="title is-size-2 has-text-centered">
			Edit Risk Criteria
		</h1>

		<b-field grouped v-for="criteria of riskCriteria" v-bind:key="criteria.id">
			
			
			<b-field>
				<b-checkbox 
				class="alignfix"
				v-model="criteria.active"
				size="is-large">
				</b-checkbox>
				<b-input
				required
				:disabled="!criteria.active"
				maxlength="255"
				v-model="criteria.title"  
				placeholder="Title">
				</b-input>
			</b-field>
			<b-field expanded>
				<b-input
				:disabled="!criteria.active"
				maxlength="255" 
				v-model="criteria.description"
				placeholder="Description">
				</b-input>
			</b-field>
		</b-field>
		
		<b-field grouped v-for="(addition,index) in addedCriteria" v-bind:key="index">
		<b-field>
			<b-checkbox
			class="alignfix"
			v-model="addition.active"
			size="is-large">
			</b-checkbox>
			<b-input
			required
			:disabled="!addition.active"
			maxlength="255"
			v-model="addition.title"  
			placeholder="Title">
			</b-input>
		</b-field>
		<b-field expanded>
			<b-input
			:disabled="!addition.active" 
			v-model="addition.description"
			maxlength="255"
			placeholder="Description">
			</b-input>
		</b-field>
		<b-button outlined
			@click="removeCriteria(index)"
			type="is-danger" 
			icon-right="times-circle">
		</b-button>
		</b-field>

		<b-button outlined
		@click="addCriteria()"
		class="is-pulled-left"
		type="is-primary" 
		icon-right="plus">
		</b-button>
		
		<b-loading :is-full-page=false :active="isLoading"></b-loading> 
		</div>
	</div>
		

	<div class="column is-two-thirds">
		<div class="level">
			<b-button class="level-item "
				@click="$router.go(-1)"
				type="is-dark" outlined
				size="is-medium"
				icon-left="angle-left">
				Go Back
			</b-button>
			<b-button class="level-item "
				:disabled ="!editedValues" 
				type='is-primary'
				native-type="submit" outlined
				size="is-medium"
				icon-left="angle-right">
				Confirm Details
			</b-button>
		</div> 
	</div>

	</div>
	
</form>
</section>



</template>

<script>
import API from '@/api.js'



export default {
	data() {
	return {
		riskCriteria:[
		{
			active:null,
			id:null,
			title: null,
			description:null  
		}
		],
		addedCriteria:[
		
		],
		fetchedValues:{minimum:null, list:[]},
		isLoading: true
	}
	},
	computed:{
	editedValues: function(){
		return JSON.stringify(this.riskCriteria) !== JSON.stringify(this.fetchedValues.list) || this.addedCriteria.length || this.fetchedValues.minimum !== this.minimum           
	}
	
	},
	methods:{

	async submitAction(){
		this.isLoading = true
		API.updateRiskCriteria({list:this.riskCriteria.concat(this.addedCriteria), minimum:this.minimum}).then(response =>{
			this.isLoading=false
			if(response){
				this.$buefy.dialog.alert({ 
						title: 'Risk Criteria Updated!',
						message: `Your edits have been saved. You can add and change more criteria as well as the minimum requirements, or go back to the Configuration Menu.`,
						type: 'is-success',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({name: 'Edit configuration'}),
						canCancel: true,
						cancelText:'Continue Editing',
						onCancel:() =>  API.getRiskCriteria().then(response =>{ //view is rendered for edit mode
										this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
										this.riskCriteria = response.list
										this.minimum = response.minimum
										this.addedCriteria = []
										}),
						hasIcon: true,
						icon: 'pen',
						size:'is-medium'
					})
			}else{
				API.endSession(false).then(response=>{
					this.$router.push('/login')
					return response //avoiding linter
				})
			}
			
			return response //avoiding linter
		})
	},
	addCriteria(){
		this.addedCriteria.push( {
			active:true,
			id:null,
			title: null,
			description:null  
		})
	},
	removeCriteria(index){
		this.addedCriteria.splice(index, 1)
	}
	},
	created(){
		API.getRiskCriteria().then(response =>{
			console.log(response)
			this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep original values independent
			this.riskCriteria = response.list
			this.minimum = response.minimum
			this.isLoading = false
		});
	}
	
}
</script>
<style scoped>
	.alignfix{
		align-items:normal;
	}
</style>