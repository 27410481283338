<template>
<section>
<form class="hero-body container" @submit.prevent="submitAction()" >
	<div class="box columns is-multiline is-centered">
		<div class="column is-full" v-if="['Coordinator'].includes($parent.login.userType)" >
			<div class="level">
				<b-button 
					icon-right="history" 
					size="is-small" 
					type="is-dark" outlined
					tag="router-link"
					:to="`/history${submitAddress}`">
					Change History
					<!-- What should the path be? How will API work?? -->
				</b-button>
			</div>
		</div>
	<div class="column is-half">
		<div class="container">
		
		<b-field label="Organisation" v-bind='editedField("organisation")'>
			<b-input
			v-model="formValues.organisation" 
			required
			size="is-medium"
			icon="sitemap"
			maxlength="50">
			</b-input>
		</b-field>
		

		<b-field label="Phone (optional)" v-bind='editedField("phone")'>
		<b-input
			v-model="formValues.phone"
			size="is-medium"
			icon="phone">
		</b-input>
		</b-field>
		
		<b-field label="Email (optional)" v-bind='editedField("email")'>
		<b-input
			v-model="formValues.email"
			type="email"
			size="is-medium"
			icon="envelope"
			maxlength="50">
		</b-input>
		</b-field>

		<b-field label="Address (optional)" v-bind='editedField("address")'> 
		<b-input
			v-model="formValues.address" 
			size="is-medium"
			type="textarea">
		</b-input>
		</b-field>
		<b-loading :is-full-page=false :active="isLoading"></b-loading> 
		</div>
	</div>
	<div class="column is-two-thirds">
		<div class="level">
		<b-button class="level-item "
			@click="$router.go(-1)"
			type="is-dark" outlined
			size="is-medium"
			icon-left="angle-left">
			Go Back
		</b-button>
		<b-button class="level-item "
			:disabled ="!editedDetails" 
			type='is-primary'
			native-type="submit" outlined
			size="is-medium"
			icon-left="angle-right">
			Confirm Details
		</b-button>
		</div> 
	</div>

	</div>
	
</form>
</section>



</template>

<script>
import API from '@/api.js'



export default {
	data() {
	return {
		formValues:{
		organisation:'',
		address:'',
		email:'',
		phone:''
		},
		fetchedValues:{
		organisation:'',
		address:'',
		email:'',
		phone:''
		},
		isLoading: true
	}
	},
	computed:{
	validForm: function(){ 
		let conditionList = {required: false} //add condition groups here eg: email input reqs, password input reqs
	
		//required conditions
		if (this.formValues.organisation !== null && this.formValues.active !== null ){
		conditionList.required = true
		}


		//if any of the condition groups are not met, return false
		return !Object.values(conditionList).includes(false)
	},
	editedDetails: function(){
		if (this.formMode === "Edit"){ //only disable submit button on Edit mode
			if(this.validForm){
				return (JSON.stringify(this.formValues) !== JSON.stringify(this.fetchedValues))
			}else{
				return false
			}
		}else if (this.formMode =="Add"){
			return this.validForm
		}else{
			return false
		}
	},

	submitAddress: function(){
		if(this.$route.path.substr(-1)=='/'){
			return this.$route.path.slice(0,-1)
		}
		return this.$route.path
	},
	formMode: function(){
		if (this.$route.name === "Add New Provider"){
		return "Add"
		} else if(this.$route.name === "Provider Details"){
		return "Edit"
		} else{
		return null
		}
	},
	},
	methods:{

	async submitAction(){
		if (!this.validForm){
			this.isLoading = true
			API.endSession(false).then(response=>{
				this.$router.push('/login')
				return response //avoiding linter
			})
		}else if(this.formMode === "Edit"){ //Edit Provider Success
			this.isLoading = true
			API.updateProvider(this.submitAddress, this.formValues).then(response =>{
				this.isLoading = false
				if (response !== false){
					this.$buefy.dialog.alert({ 
						title: 'Provider Updated!',                                 
						message: `The changes to ${response.organisation} have been saved.`,
						type: 'is-success',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({ name: 'Provider Management'}),
						cancelText: 'Continue Editing',
						onCancel: () => { this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
										this.formValues= response},                               //turns the reference value (object) into a primitive value (string) and then changes back to required type (object)
						canCancel: true,
						hasIcon: true,
						icon: 'sitemap',
						size:'is-medium'
					})
				}else{
					//alert DB and end Session
					API.endSession(false).then(response=>{
						this.$router.push('/login')
						return response//avoiding linter
					})
				}
			})
		}else if (this.formMode === "Add"){ //Add Provider Success
			this.isLoading = true;
			
			API.insertProvider(this.formValues).then(response=>{
				if (response !== false){
					this.isLoading = false;

					this.$buefy.dialog.alert({ 
						title: 'Provider Created!',                                 
						message: `You can view and edit the details of <b><a href="/prov/${response.id}" target="_blank"></b>${response.organisation} here.</a> `,
						type: 'is-success',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({ name: 'Provider Management'}),
						hasIcon: true,
						icon: 'sitemap',
						size:'is-medium'
					});
				}else{
					//alert DB and end Session
					API.endSession(false).then(response=>{
						this.$router.push('/login');
						return response //avoiding linter
					});
				}
			})
		}
	},

	editedField(detail){
		if(this.formMode === "Edit"){
		if (this.fetchedValues[detail] !== this.formValues[detail]){
			return {type:"is-warning", message:`Changed from ${this.fetchedValues[detail]}`}
		} 
		}
	}
	},
	
	created(){
	if (this.formMode === "Edit"){
		API.getProvider(this.submitAddress).then(response =>{ //view is rendered for edit mode
		if(response == false){ //When inactive/non-existing user
			this.$buefy.dialog.alert({ 
				title: 'Cannot Access Provider',                                 
				message: `There is no accessible Provider with this ID.`,
				type: 'is-warning',
				confirmText: 'Go Back',
				onConfirm: () => this.$router.push({ name: 'Provider Management'}),
				hasIcon: true,
				icon: 'times-circle',
				size:'is-medium'
			})
		}else{
			this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
			this.formValues= response 
		}

		})
	}

	},
	async mounted(){
	if (this.formMode === "Add"){
		setTimeout(() => { //view is rendered add mode
			this.isLoading = false 
		}, 0)
	}
	},
	updated(){
	if(!this.editedDetails){
		this.isLoading = false
	}
	}
	
}
</script>
