<template>
	<section>
		<form class="hero-body container" @submit.prevent="submitAction()" >
			<div class="box columns is-multiline is-centered">
				<div class="column is-full" v-if="['Coordinator', 'Manager'].includes($parent.login.userType) &&formMode=='Edit'" >
					<div class="level">
						<b-button 
							icon-right="history" 
							type="is-dark" outlined
							tag="router-link"
							:to="`/history${submitAddress}`">
							Change History
							<!-- What should the path be? How will API work?? -->
						</b-button>
					</div>
				</div>
				<div class="column is-two-thirds">
					<div class="container">
						<div class="columns">
							<b-field label="Active" class="column is-2" v-if="formMode=='Edit'" v-bind='editedField("active")'>
								<b-checkbox class="column"
									:disabled="!canDeactivate"
									v-model="formValues.active"
									@input="resetChanges()"
									icon="phone"
								/>
							</b-field>
							<b-field label="User Role" class="column is-one-third" v-bind='editedField("type")'>
								<b-select 
									v-model="formValues.type" 
									placeholder="Select a Type" 
									required 
									expanded 
									@input="clearSelect()" 
									:disabled="(!this.activeUser && formMode=='Edit') || this.$parent.login.userID==this.$route.params.id"
									>
									<option value="Staff">Staff</option>
									<option value="Manager">Manager</option>
									<option value="Accounts">Accounts</option>
									<option value="Coordinator" v-if="$parent.login.userType=='Coordinator'">Coordinator</option><!--!this.activeUser && formMode=='Edit' && ($parent.login.userType!=='Manager' ||$parent.login.userType!='Coordinator')-->
								</b-select>
							</b-field>
							<b-field label="Provider Organisation" class="column is-half" v-if="formValues.type!='Coordinator'" v-bind='editedField("provider_id")'>
								<b-select 
									v-model="formValues.provider_id" 
									placeholder="Select a Provider" 
									required 
									expanded 
									:disabled="(this.provOptions.length==1 || !this.activeUser) && formMode=='Edit'" v-if="$parent.login.userType=='Coordinator'">
									<option v-for="prov of provOptions" v-bind:key="prov.id" v-bind:value="prov.id" >{{prov.organisation}}</option>
								</b-select>
								<b-select v-else
									v-model="formValues.provider_id"
									placeholder="Select a Provider" 
									required expanded 
									disabled>
										<option :value="formValues.provider_id" selected>{{formMode=='Edit' ? fetchedValues.organisation : provOptions.organisation}}</option>
								</b-select>
							</b-field>
						</div>
						<b-field label="Phone (optional)" v-bind='editedField("phone")'>
							<b-input
								:disabled="!this.activeUser && formMode=='Edit'"
								v-model="formValues.phone"
								icon="phone"
							/>
						</b-field>
						<b-field label="Email" v-bind='editedField("email")'>
							<b-input
								:disabled="!this.activeUser && formMode=='Edit'"
								v-model="formValues.email"
								required
								type="email"
								icon="envelope"
							/>
						</b-field>
						<b-field label="Username" v-bind='editedField("username")'>
							<b-input
								:disabled="!this.activeUser && formMode=='Edit'"
								v-model="formValues.username" 
								required
								icon="user"
								validation-message="Please fill out this field." 
								pattern="(?!\s*$)((\s*\S*\s*)*)"
								@blur="formValues.username= formValues.username.trim()"
								maxlength="50"
							/>
								<!--for no spaces in username use ^(\s*\S*\s*)$ -->
						</b-field>
						<b-field label="Password" v-if="formMode ==='Add'"> 
							<b-input
								v-model="formValues.password" 
								required
								icon="key"
								type="password"
								password-reveal
							/>
						</b-field>
						<div class="level" v-else>
							<b-button class="level-item"
								:disabled="this.$parent.login.userID!=this.$route.params.id"
								@click="$router.push({ path:`${submitAddress}/reset`})"
								type="is-primary" 
								icon-left="key">
								Reset Password
							</b-button>
						</div>
						<b-loading :is-full-page=false :active="isLoading"></b-loading> 
					</div>
				</div>

				<div class="column is-two-thirds">
					<div class="level">
						<b-button 
							class="level-item"
							@click="$router.go(-1)"
							type="is-dark" 
							outlined
							icon-left="angle-left">
							Go Back
						</b-button>
						<b-button 
							class="level-item"
							:disabled ="!editedDetails" 
							type='is-primary'
							native-type="submit" 
							outlined
							icon-left="angle-right">
							Confirm
						</b-button>
					</div> 
				</div>
			</div>
		</form>
	</section>
</template>

<script>
import API from '@/api.js'



export default {
	data() {
	return {
		formValues:{
		type:'',
		provider_id:'',
		phone:'',
		email:'',
		username:'',
		password:''
		},
		fetchedValues:{
		type:'',
		provider_id:'',
		phone:'',
		email:'',
		username:''
		},
		changes:[],
		isLoading: true,
		provOptions:[{id:null,organisation:null,email:null,phone:null,address:null}]
	}
	},
	computed:{
	validForm: function(){ 
		let conditionList = {required: false, blank:false} //add condition groups here eg: email input reqs, password input reqs
	
		//required conditions
		if (this.formValues.type !== null && this.formValues.email !== null && this.formValues.username !== null && this.formValues.password !== null){
			conditionList.required = true
		}
		if(/^\s*$/.test(this.formValues.username)==false){
			conditionList.blank = true
		}

		//if any of the condition groups are not met, return false
		return !Object.values(conditionList).includes(false)
	},
	editedDetails: function(){
		if (this.formMode === "Edit"){ //only disable submit button on Edit mode
			let changed = false
			for( let i in this.fetchedValues){
				if(this.fetchedValues[i] !== this.formValues[i]){
					changed=true
					break
				}
			}
			if(this.validForm){
				return changed
			}else{
				return false
			}
		}else if (this.formMode =="Add"){
			return this.validForm
		}else{
			return false
		}
	},

	submitAddress: function(){
		if(this.$route.path.substr(-1)=='/'){
			return this.$route.path.slice(0,-1)
		}
		return this.$route.path
	},
	formMode: function(){
		if (this.$route.name === "Add New User"){
		return "Add"
		} else if(this.$route.name === "User Details"){
		return "Edit"
		} else{
		return null //avoiding linter
		}
	},
	canDeactivate: function(){
		if(this.$parent.login.userID==this.$route.params.id){
			return false
		}
		if (this.formMode =="Add"){
			return false
		}
		if(this.formMode=="Edit"){
			return true
		}
		return false //avoiding linter
	},
	activeUser: function(){
		return this.formValues.active
	}
	},
	methods:{
	clearSelect(){
		if (this.formValues.type==="Coordinator"){
		this.formValues.provider_id = null;
		}
	},

	resetChanges(){
		
		if (this.activeUser==false){ //if someone decides they don't want to activate the user part-way through editing fields
		for (let x in this.formValues){
			if (x!="active"){
			this.formValues[x] =this.fetchedValues[x]
			}
		}
		}
	},


	async submitAction(){
		if (!this.validForm){
		this.isLoading = true
		API.endSession(false).then(response=>{
			this.$router.push('/login')
			return response //avoiding linter
		})
		}else if(this.formMode === "Edit"){ //Edit User Success
			this.formValues.changes=this.changes
			this.isLoading = true
			API.updateUser(this.submitAddress, this.formValues).then(response =>{ //this.changes add in somehow
				this.isLoading = false
				if(response.duplicates!== undefined){
					this.isLoading = false

					this.$buefy.dialog.alert({ 
						title: 'Clash with Existing User ',                                 
						message: `Please change the following user details, which have been detected as duplicates: <b>${response.duplicates.toString().replace(',', ', ')}</b>.`,
						type: 'is-warning',
						confirmText: 'Back to Form',
						hasIcon: true,
						icon: 'user-edit',
						size:'is-medium'
					})

				}else if (response !== false){
					this.$buefy.dialog.alert({ 
						title: 'User Updated!',                                 
						message: `The changes to ${response.username} have been saved.`,
						type: 'is-success',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({ name: 'User Management'}),
						cancelText: 'Continue Editing',
						onCancel: () => { this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
										this.formValues= response},                               //turns the reference value (object) into a primitive value (string) and then changes back to required type (object)
						canCancel: true,
						hasIcon: true,
						icon: 'user-edit',
						size:'is-medium'
					})
					
				}else{
				//alert DB and end Session
				API.endSession(false).then(response=>{
					this.$router.push('/login')
					return response //avoiding linter
				})
				}
				

			})
		}else if (this.formMode === "Add"){ //Add User Success
		this.isLoading = true
		
		API.insertUser(this.formValues).then(response=>{
			if (response.userID !==undefined){
				this.isLoading = false

				this.$buefy.dialog.alert({ 
					title: 'User Created!',                                 
					message: `You can view and edit the details of <b><a href="/user/${response.userID}" target="_blank"></b>${response.username} here.</a> `,
					type: 'is-success',
					confirmText: 'Go Back',
					onConfirm: () => this.$router.push({ name: 'User Management'}),
					cancelText: 'Add more Users',
					onCancel: () => { for (let key in this.formValues) { this.formValues[key] = null }},
					canCancel: true,
					hasIcon: true,
					icon: 'user-plus',
					size:'is-medium'
				})
			}else if(response.duplicates!== undefined){
				this.isLoading = false

				this.$buefy.dialog.alert({ 
					title: 'Clash with Existing User ',                                 
					message: `Please change the following user details, which have been detected as duplicates: <b>${response.duplicates.toString().replace(',', ', ')}</b>.`,
					type: 'is-warning',
					confirmText: 'Back to Form',
					hasIcon: true,
					icon: 'user-edit',
					size:'is-medium'
				})

			}else{
			//alert DB and end Session
			API.endSession(false).then(response=>{
				this.$router.push('/login')
				return response //avoiding linter
			})
			}
		})
		}
	},

	editedField(detail){
		
		if(this.formMode === "Edit" ){
			
			if (this.fetchedValues[detail] !== this.formValues[detail]){
				console.log(this.fetchedValues[detail], this.formValues[detail])
				if(!this.changes.includes(detail)){
					this.changes.push(detail)
				}
				if (detail==="provider_id"){
					return {type:"is-warning", message:`Changed from ${this.fetchedValues.organisation}`}
				}
				return {type:"is-warning", message:`Changed from ${this.fetchedValues[detail]}`}
			} 
		}
	}
	},
	beforeRouteUpdate (to, from, next) { //when switching between /user/x and /user/y
		this.isLoading=true;
		console.log(to, from, next)
		if (this.formMode === "Edit"){
			API.getUser(to.path).then(response =>{ //view is rendered for edit mode
				console.log("response")
				if(response == false){ //When inactive/non-existing user
					this.$buefy.dialog.alert({ 
						title: 'Cannot Access User',                                 
						message: `There is no accessible User with this ID.`,
						type: 'is-warning',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({ name: 'User Management'}),
						hasIcon: true,
						icon: 'user-times',
						size:'is-medium'
					})
				}else{
					console.log("response gotten")
					this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
					this.formValues= response 
					this.isLoading = false	
					next()
				}
			})
		}
		
		
	},

	created(){
		API.getProvOpt().then(response=>{
			this.provOptions = response.content
		})
		if(this.$parent.login.provider!=null){
			this.formValues.provider_id= this.$parent.login.provider
		}
		if (this.formMode === "Edit"){
			API.getUser(this.submitAddress).then(response =>{ //view is rendered for edit mode
				console.log("response")
				if(response == false){ //When inactive/non-existing user
					this.$buefy.dialog.alert({ 
						title: 'Cannot Access User',                                 
						message: `There is no accessible User with this ID.`,
						type: 'is-warning',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({ name: 'User Management'}),
						hasIcon: true,
						icon: 'user-times',
						size:'is-medium'
					})
				}else{
					console.log("response gotten")
					this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
					this.formValues= response 
					this.isLoading = false	
				}
			})
		}
	},
	async mounted(){
	if (this.formMode === "Add"){
		setTimeout(() => { //view is rendered add mode
			this.isLoading = false 
		}, 0)
	}
	}
	
}
</script>
