<template>
  <section class="columns is-centered">
    <div class="hero-body column container">
      <div class="box columns is-multiline">
        <div class="column is-full" v-if="['Coordinator', 'Manager'].includes($parent.login.userType)" >
          <div class="level">
            <b-button 
              icon-right="history" 
              size="is-small" 
              type="is-dark" outlined
              tag="router-link"
              :to="`/history${submitAddress}`">
              Change History
            </b-button>
          </div>
        </div>

        <div class="column is-half">
          <div v-if="isBorn">
            <h1 class="title is-size-1">
              {{child.name}} 
            </h1>
            <h1 class="subtitle is-size-3">
              <br>
              <strong>Primary Caregiver:</strong>  {{child.caregiver}}
              <br>
              <strong>NHI:</strong>  {{child.nhi}}
              <br>
              <strong>DOB:</strong>  {{child.date_of_birth}}
              <br>
            </h1>               
          </div>
          <div v-else>
            <h1 class="title is-size-1">
              {{child.name ? child.name + " (Pregnancy)" : "Pregnancy"}} 
            </h1>
            <h1 class="subtitle is-size-3">
              <strong>Primary Caregiver:</strong>  {{child.caregiver}}              
              <br>
              <strong>EDD:</strong>  {{child.expected_due_date}}
              <br>
            </h1> 
          </div>
        </div>

        <div class="tile is-vertical column is-half">
          <h2 class="box is-size-3 has-text-centered has-text-weight-semibold tile is-child " v-if="isEligible">
            Funding Available: <br>
            {{new Intl.NumberFormat('en-NZ', { style: 'currency', currency: 'NZD' }).format(child.avail_funds)}}  
          </h2>
          <h2 class="box is-size-3 has-text-centered has-text-weight-semibold tile is-child has-text-danger" v-else>
            <b-icon icon="times-circle" size="is-large"></b-icon><br>
            Child is ineligible for funding <br>(over {{child.maxAge}} years)
          </h2>
            <b-button class="has-text-centered tile " v-if="isEligible"
            tag="router-link"
            :disabled="child.avail_funds==0"
            :to="{name: 'New Request for Funding',params: { targetChild:child}}" 
            type='is-primary' outlined 
            size="is-size-3"
            icon-left="plus">
            New Submission
            </b-button>
        </div>  

        <div class="column">
          <b-button 
            @click="$router.go(-1)"
            type="is-dark" outlined
            size="is-large"
            icon-left="angle-left">
            Go Back
          </b-button>
          <b-button 
            tag="router-link"
            :to="`${submitAddress}/edit`"
            type="is-primary" outlined
            size="is-large"
            icon-left="pen">
            Edit Child
          </b-button>
        </div>

        <div class="column is-full">
          <ListTable 
          :isLoading="isLoading" 
          :HighlightValuePair="HighlightValuePair"

          :columns="columns" 
          :rows="submissions" 
          rowRoute="Submission Details" 

          :pagination="pagination"
          :total-elements="totalElements"
          @change-pagination="pagination = $event"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import API from '@/api.js'
import ListTable from '../../components/layout/ListTable.vue'

export default {
  components: { ListTable },
  data () {
    return {
      child:{
        nhi: null,
        name: null,
        caregiver: null,
        avail_funds: null,
        date_of_birth: 0,
        expected_due_date: null
      },

      columns: [
        { field: 'stage', label: 'Stage', width: '150', sortable: true },
        { field: 'organisation', label: 'Provider Organisation',	width:'200', sortable: true },
        { field: 'status', label: 'Status', width: '150',sortable: true },
        { field: 'req_funding', label: 'Requested Funds', width: '150', sortable: true },
        { field: 'description', label: 'Description', width: '300', sortable: false },
        { field: 'date', label: 'Date', sortable: true, numeric: true }
      ],
      pagination: { // Default pagination options
        page: 0,
        size: 10,
        sortBy: 'date',
        sortOrder: 'desc',
      },
      totalElements: 0, 

      submissions:[
        {id: null}
      ],
      isLoading:null
    }
  },
  watch: { // If change in table data options are detected, update the URL and fetch the data
    pagination: {
      deep: true,
      handler() {
        this.getChildSubmissions();
      }
    },
  },
  computed:{
    isBorn(){
      if(this.child.date_of_birth == null){
        if(this.name==null){
        this.name = "Pregnancy" /* eslint-disable-line  vue/no-side-effects-in-computed-properties*/
        }else{
        this.name += " (Pregnancy)" /* eslint-disable-line  vue/no-side-effects-in-computed-properties*/
        }
        return false
      }else{
        return true
      }
    },
    isEligible(){
      if(this.child.date_of_birth == 0 || this.child.date_of_birth == null){
        return true
      }
      let date = new Date(this.child.date_of_birth)
      let validuntil = new Date(date.setFullYear(date.getFullYear() + Number(this.child.maxAge)))
      return (new Date) < (validuntil)
    },
    submitAddress: function(){
      if(this.$route.path.substr(-1)=='/'){
        return this.$route.path.slice(0,-1)
      }
      return this.$route.path
    },
    HighlightValuePair:function(){
      let styles = {
        'status':{
          'Completed':'is-success',
          'Rejected':'is-danger',
          'In Progress': 'statusMatch'
        }
      }
      //user specific
      if(this.$parent.login.provider!=null){
        styles.provider_id = {[this.$parent.login.provider]: 'provMatch'}
      }else{
        styles.provider_id = {'all': 'provMatch'}
      } 
      
      
      switch (this.$parent.login.userType){
        case 'Staff':
          styles.stage = {'Follow-Up':'stageMatch',
                  'Referral':'stageMatch'}
        break;
        case 'Manager':
          styles.stage = {'Review':'stageMatch'}
        break;
        case 'Coordinator':
          styles.stage = {'ETER Approval':'stageMatch'}
        break;
        case 'Accounts':
          styles.stage = {'Purchasing':'stageMatch'}
        break;
      }
      
      return styles
      
    }
    
  },

  methods: {
    getChild () {
      API.getChild(this.submitAddress).then(response =>{
        if (response){
          this.child = response
        } else {
          this.$router.push({ name: 'Caseload' })
        }
      })
    },
    getChildSubmissions () {
      const payload = this.getPayload();
      API.getChildSubmissions(payload).then(response =>{
        this.isLoading = false
        this.submissions = response.content
        this.totalElements = response.totalElements
        for (let i of this.submissions){
          i.req_funding = new Intl.NumberFormat('en-NZ', { style: 'currency', currency: 'NZD' }).format(i.req_funding)
          i.date= new Date(i.date).toDateString()
        }
      })
    },
    getPayload () { // Constructs the request payload object
      return {
        // Pagination options
        page: this.pagination.page,
        size: this.pagination.size,
        sortBy: this.pagination.sortBy,
        sortOrder: this.pagination.sortOrder,

        submitAddress: this.submitAddress
      }
    }
  },
  created () {
    this.isLoading = true;
    this.getChild();
    this.getChildSubmissions();
  }
}
</script>

