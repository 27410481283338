import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import Routes from './router.js'

import Buefy from 'buefy'
//import 'buefy/dist/buefy.css'

//HTTP requests defined by me
import API from '@/api.js'


import { library } from '@fortawesome/fontawesome-svg-core'
//To use icons, find the name off fontawesome, then add to both the lists below


import { 
  faCheck, 
  faCheckCircle, 
  faInfoCircle, 
  faExclamationTriangle, 
  faExclamationCircle,
  faArrowUp, 
  faAngleRight, 
  faAngleLeft, 
  faAngleDown,
  faEye, 
  faEyeSlash, 
  faCaretDown, 
  faCaretUp, 
  faUpload, 
  faUser, 
  faEnvelope, 
  faKey, 
  faPhone,
  faPen,
  faUserPlus,
  faTimesCircle,
  faUserEdit,
  faUserTimes,
  faSearch,
  faUserSlash,
  faUserMinus,
  faSignOutAlt,
  faPlus,
  faDollarSign,
  faPaperclip,
  faSitemap,
  faMinus,
  faSave,
  faUndo,
  faDownload,
  faHistory,
  faCalendarDay,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faCheck, 
  faCheckCircle, 
  faInfoCircle, 
  faExclamationTriangle, 
  faExclamationCircle,
  faArrowUp, 
  faAngleRight, 
  faAngleLeft, 
  faAngleDown,
  faEye, 
  faEyeSlash, 
  faCaretDown, 
  faCaretUp, 
  faUpload, 
  faUser, 
  faEnvelope, 
  faKey, 
  faPhone,
  faPen,
  faUserPlus,
  faTimesCircle,
  faUserEdit,
  faUserTimes, 
  faSearch,
  faUserSlash,
  faUserMinus,
  faSignOutAlt,
  faPlus,
  faDollarSign,
  faPaperclip,
  faSitemap,
  faMinus,
  faSave,
  faUndo,
  faDownload,
  faHistory,
  faCalendarDay,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faChevronRight,
  faFilter
  );

Vue.component('vue-fontawesome', FontAwesomeIcon);




Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

Vue.use(VueRouter)

const router = new VueRouter({
	routes: Routes,
	linkActiveClass: "is-active",
	linkExactActiveClass: '',
  mode:'history'
})

// Router Navigation Guards

//In future, might remove this section, or shift it into per route guards (router.js).
//it performs a redirect based on authentication and user details. 
//It makes a call to the database on every URL change, which returns the userID, userType, username, provider
//is this sustainable? There are already authentication checks made in the databaseAPI middleware function, and conditions in each request - no sensitive information displayed. Perhaps doubling up on checks?
//should redirecting action be managed within the backend server instead?
router.beforeEach((to, from, next) => {
  try{
    API.authCheck().then((response) =>{                           //on every route change, check authority
      
      if (to.matched.some(record => record.meta.requiresAuth)){   //if route requires logged in user 
          if (response.error!= undefined){                        //if session invalid or nonexistent, redirect to login 
            next('/login')
          }else{                                                  //if session valid, set Vue's root value to the response 
            router.app.$children[0].login= response.user               //mayhaps breaking best practice
                                                                        //this points to the App.vue component data, rather than the root instance's data (at the bottom of this file)
            //ADDITIONAL PERMISSION CHECKS
            if(to.matched.some(record => record.meta.typePermissions) && to.meta.typePermissions.includes(response.user.userType)){ //if the route requires a userType to access
				//check the user type of authenticated session is one of the permitted
				next()  
             
			}else if(to.matched.some(record => record.meta.personalPage) && to.params.id == response.user.userID){ //if user is permitted to view their own page
				next()
			}else if(Object.keys(to.meta).length===1){
				next()
			}
            //PERMISSIONS NOT MET
            else{
             
              next('/home')                                                //allow navigation if not additional permissions required
            }
            
            
          }
  




      }else if(to.matched.some(record=> !record.meta.requiresAuth)){ //if route is for unauthenticated users (ie login page)
        if (response.error!= undefined){                             //if session invalid or nonexistent, allow navigation 
          next()
        }else{                                                       //if session is for authenticated user 
          router.app.$children[0].login= response                    //ensure root value is still according to the session
          next('/home')                                              //redirect to landing page
        }
      }else{                                                     
        next()                                                       //if route does not have meta tag, allow navigation     
      }
    })
  }catch(e){
    console.log("owo")

    //// Remove Session?? May have a probolem trying to delete sessions that aren't existing in the store - can't delete session of non-authenticated user
    // API.endSession(false).then(response=>{
    //   this.$router.push('/login')
    // })

    next(false)                                                     //if for some reason authCheck could not be run, abort navigation
  }                               
})




Vue.config.productionTip = false //console cleanup

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
