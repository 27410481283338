<template>
  <b-dropdown
    v-model="selectedFilters"
    aria-role="list"
    multiple
    @change="$emit('change-filter', $event)"
  >
    <template #trigger>
      <b-button
        type="is-primary"
        icon-left="chevron-down"
      >
        {{ headerNameComputed }} ({{ selectedFilters.length }})
      </b-button>
    </template>
    <b-dropdown-item 
      v-for="item of filteredData" 
      :key="item" 
      :value="item" 
      aria-role="listitem"
    >
      {{item}}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'FilterDropdown',
  props: {
    filterList: { type: Array, required: true },
    selectedFilters: { type: Array, required: true },
    filterInput: { type: String, default: '' },
    headerName: { type: String, default: 'Header'}
  },
  computed: {
    filteredData () {
      return this.filterList.filter((item) => item.toLowerCase().indexOf(this.filterInput.toLowerCase()) >= 0);
    },
    headerNameComputed() {
      return `Filter by ${this.headerName}`
    },
    searchLabelComputed () {
      return `Search for ${this.headerName}`
    }
  },
}
</script>
