import axios from 'axios'
axios.defaults.withCredentials = true //Cross Origin Cookies
let serverURL = "http://localhost:8081/api"
let clientURL = "http://localhost:8080"
if(Object.keys(process.env).length>2){ //process.env will always at least have NODE_ENV and BASE_URL
    serverURL = process.env.VUE_APP_SERVER_URL
    clientURL = process.env.VUE_APP_CLIENT_URL
}

export default{
    getRows(table, offset, limit, search, sort){
        let params = {
            offset: offset,
            limit: limit,
            search: search,
            sort: sort
        }
        try{	//how to do this gracefully??
            return axios.get(`${serverURL}/list/${table}`, {params:params}).then((resp)=>{
                console.log(table, offset, limit, search, sort)
                return resp.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
	},
    getProviderList(payload = {
        page: 0, 
        size: 10,
        sortBy: 'date',
        sortOrder: 'desc',
        }
    ){
        try{
            return axios.get(`${serverURL}/prov/page=${payload.page}&size=${payload.size}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}`).then((resp)=>{
                return resp.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    getProvider(providerAddress){
        try{
            return axios.get(serverURL + providerAddress,).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    getHistory(payload = {
        page: 0, 
        size: 10,
        sortBy: 'date',
        sortOrder: 'desc',
        submitAddress: null,
        }
        ){
        try{
            return axios.get(`${serverURL}${payload.submitAddress}/page=${payload.page}&size=${payload.size}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}`).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    updateProvider(providerAddress, details){
        try{
            return axios.post(serverURL + providerAddress, details).then((resp)=>{
                if (resp.status == 200){
                    console.log(resp.data)
                    return resp.data
                }
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    insertProvider (details){
        try{
            return axios.post(`${serverURL}/prov/add`, details).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
                    
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    getConfiguration(){
        try {
            return axios.get(`${serverURL}/configuration`).then((resp)=>{
                return resp.data.results;
            });
        } catch (err) {
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    updateConfiguration(changes){
        try {
            return axios.post(`${serverURL}/configuration`, changes).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
            });
        } catch(err) {
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    getRiskCriteriaOptions(){
        try{
            return axios.get(`${serverURL}/options/riskcriteria`).then((resp)=>{
                for (let i of resp.data.list){
                    i.active = Boolean(i.active)
                }
                return resp.data
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    getRiskCriteria(){
        try{
            return axios.get(`${serverURL}/riskcriteria`).then((resp)=>{
                for (let i of resp.data.list){
                    i.active = Boolean(i.active)
                }
                return resp.data
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    updateRiskCriteria(criteria){
        try{
            return axios.post(`${serverURL}/riskcriteria`, criteria).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
                    
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    getCategoryOptions(){
        try{
            return axios.get(`${serverURL}/options/category`).then((resp)=>{
                return resp.data
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    getCategories(){
        try{
            return axios.get(`${serverURL}/category`).then((resp)=>{
                for (let i of resp.data){
                    i.active = Boolean(i.active)
                }
                return resp.data
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    updateCategories(categories){
        try{
            return axios.post(`${serverURL}/category`, categories).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
                    
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    updateChild(childID, details){
        if(details.date_of_birth==null || details.date_of_birth.toJSON()==null){
            // console.log(details.expected_due_date.getTimezoneOffset())
            // console.log(details.expected_due_date.toISOString())
            let converted = new Date(details.expected_due_date.getTime() - details.expected_due_date.getTimezoneOffset()*60*1000)
            // console.log(converted)
            // console.log(converted.toJSON())
            details.expected_due_date=converted
        }else{
            // console.log(details.date_of_birth.getTimezoneOffset())
            // console.log(details.date_of_birth.toISOString())
            let converted = new Date(details.date_of_birth.getTime() - details.date_of_birth.getTimezoneOffset()*60*1000)
            // console.log(converted)
            // console.log(converted.toJSON())
            details.date_of_birth= converted
        }
        try{
            return axios.post(`${serverURL}/child/${childID}`, details).then((resp)=>{
                if(resp.data.date_of_birth){
                    resp.data.date_of_birth= new Date(resp.data.date_of_birth)
                }else{
                    resp.data.expected_due_date= new Date(resp.data.expected_due_date)
                }
                resp.data.pregnancy= Boolean(resp.data.pregnancy)
                if (resp.status == 200){
                    return resp.data
                }
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    insertChild(details){
        if(details.date_of_birth==null || details.date_of_birth.toJSON()==null){
            // console.log(details.expected_due_date.getTimezoneOffset())
            // console.log(details.expected_due_date.toISOString())
            let converted = new Date(details.expected_due_date.getTime() - details.expected_due_date.getTimezoneOffset()*60*1000)
            // console.log(converted)
            // console.log(converted.toJSON())
            details.expected_due_date=converted
        }else{
            // console.log(details.date_of_birth.getTimezoneOffset())
            // console.log(details.date_of_birth.toISOString())
            let converted = new Date(details.date_of_birth.getTime() - details.date_of_birth.getTimezoneOffset()*60*1000)
            // console.log(converted)
            // console.log(converted.toJSON())
            details.date_of_birth= converted
        }
        try{
            return axios.post(`${serverURL}/child/add`, details).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    getProvOpt (){
        try{
            return axios.get(`${serverURL}/options/prov`).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    insertUser (details){
        try{
            return axios.post(`${serverURL}/user/add`, details).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
                    
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    getUserList(payload = {
        page: 0, 
        size: 10,
        sortBy: 'date',
        sortOrder: 'desc',
        }
    ){
        try{
            return axios.get(`${serverURL}/users/page=${payload.page}&size=${payload.size}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}`).then((resp)=>{
                for (let i of resp.data.content){
                    if(i.active){
                        i.active = "Active"
                    }else{
                        i.active ="Inactive"
                    }
                }
                return resp.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    getUser(userAddress){
        try{
            return axios.get(serverURL + userAddress).then((resp)=>{
                if (resp.status == 200){
                    if(resp.data){
                        resp.data.active=Boolean(resp.data.active)
                    }
                    return resp.data
                }
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    updateUser(userAddress, details){
        try{
            return axios.post(serverURL + userAddress, details).then((resp)=>{
                if (resp.status == 200){
                    return resp.data
                }
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    
    getCaseload(payload = {
        page: 0, 
        size: 10,
        sortBy: 'date',
        sortOrder: 'desc',
        search: ''
        }
    ) {
        try{
            return axios.get(`${serverURL}/caseload/page=${payload.page}&size=${payload.size}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}&search=${payload.search}`).then( response => response.data )
        } catch ( err ) {
            console.log( err ) /* eslint-disable-line no-console */
        }
    },
    getTotalETER(){
        try{
            return axios.get(`${serverURL}/totalETER`).then( response =>{
                return response.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    getChild(childAddress){
        try{
            
            return axios.get(serverURL + childAddress).then( response =>{
                return response.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    getChildSubmissions(payload = {
        submitAddress: null,
        page: 0, 
        size: 10,
        sortBy: 'date',
        sortOrder: 'desc',
        }
        ){
        try{
            return axios.get(serverURL + payload.submitAddress +`/submissions/page=${payload.page}&size=${payload.size}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}`).then( response =>{
                return response.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    exportUserSubmissions(payload = {
        stage: [],
        status: [],
        date: [],
        search: ''
        }
    ){
        try{
            const query = [
                `search=${payload.search}`,
                `stage=${payload.stage}`,
                `status=${payload.status}`,
                `date=${payload.date}`
            ]
            return axios.get(serverURL +`/submissions/export/${query.join('&')}`).then( response =>{
                return response.data
            })
        }catch(err){
            console.log(err)
        }
    },
    getUserSubmissions(payload = {
        page: 0, 
        size: 10,
        sortBy: 'date',
        sortOrder: 'desc',
        stage: [],
        status: [],
        date: [],
        search: ''
        }
    ){
        try{
            const query = [
                `search=${payload.search}`,
                `page=${payload.page}`,
                `size=${payload.size}`,
                `sortBy=${payload.sortBy}`,
                `sortOrder=${payload.sortOrder}`,
                `stage=${payload.stage}`,
                `status=${payload.status}`,
                `date=${payload.date}`
            ]
            return axios.get(serverURL +`/submissions/user/${query.join('&')}`).then( response =>{
                return response.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    getSubState(subAddress){
        try{
            
            return axios.get(serverURL+ subAddress).then( response =>{
                return response.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    deleteAttachment(filename, subAddress){
        try{
            return axios.get(`${serverURL+subAddress}/${filename}/delete`).then( response =>{
                return response.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    getAttachmentDownload(filename, fileId){

        try{
            axios({
                url: `${serverURL}/download/${fileId}`,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                console.log(response)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                return true;
            });
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
        }
    },
    insertSubmission(details){
        console.log("sending")
        console.log(details)
        console.log(details.get('file'))
        try{
            return axios.post(`${serverURL}/sub/add`, details).then((resp)=>{
                console.log(resp)
                if(resp.data==false){
                    this.endSession(true)

                }else if (resp.status == 200){
                    return resp.data
                }
                
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    updateSubmission(subAddress, details){
        
        try{
            return axios.post(serverURL+subAddress, details).then((resp)=>{
                
                if (resp.status == 200){
                    return resp.data
                }
                
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    saveSubmission(subAddress, details){
        
        try{
            return axios.post(serverURL+subAddress, details).then((resp)=>{
                
                if (resp.status == 200){
                    return resp.data
                }
                
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    rejectSubmission(subAddress, details){
        
        try{
            return axios.post(serverURL+subAddress, details).then((resp)=>{
                console.log(resp)
                if (resp.status == 200){
                    return resp.data
                }
                
                
                
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            return false;
        }
    },
    attemptLogin(userDetails){
        try{
            return axios.post(`${serverURL}/login`,userDetails).then( response =>{
                console.log(response)
                return response.data
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            
        }
    },
    authCheck(){
        try{
            return axios.get(`${serverURL}/status`).then( response =>{
                
                //redirect to error page if error
                return response.data
                
                
            })
        }catch(err){

            console.log(err) /* eslint-disable-line no-console */
            
        }
    },
    findRecoveryEmail(email){
        try{
            return axios.post(`${serverURL}/resetrequest`, {email: email}).then( response =>{
                
                //redirect to error page if error
                return response.data
                
                
            })
        }catch(err){

            console.log(err) /* eslint-disable-line no-console */
            
        }
    },
    checkRecoveryLink(pathtoken){
        try{
            return axios.post(`${serverURL}/tokenreset`, {token: pathtoken}).then( response =>{
                
                //redirect to error page if error
                return response.data
                
                
            })
        }catch(err){

            console.log(err) /* eslint-disable-line no-console */
            
        }
    },
    resetPassword(newCred){
        try{
            return axios.post(`${serverURL}/reset`, newCred).then( response =>{
                
                //redirect to error page if error
                return response.data
                
                
            })
        }catch(err){

            console.log(err) /* eslint-disable-line no-console */
            
        }
    },
    endSession(alertDB){ //if true, it is a logout due to suspicious activity 
        //untested
        
        try{
            if (alertDB){
                console.log("Suspicious activity")
            }
            return axios.post(`${serverURL}/logout`, {alert: alertDB}).then( (response) =>{
                window.location.replace(`${clientURL}/login`)
                return response //avoiding linter
            })
        }catch(err){
            console.log(err) /* eslint-disable-line no-console */
            
        }
    }

}



