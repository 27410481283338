//import page components
import Login from './pages/Authentication/Login.vue';
import PassRecovery from './pages/Authentication/PassRecovery.vue';
import PassRecoveryLink from './pages/Authentication/PassRecoveryLink.vue';
import UserForm from './pages/UserManagement/UserForm.vue';
import UserManagement from './pages/UserManagement';
import ProviderManagement from './pages/ProviderManagement';
import ProviderForm from './pages/ProviderManagement/ProviderForm.vue';
import Landing from './pages/UserLanding';
import Initial from './pages/SubmissionStage/Stage1_Staff_Initial.vue';
import Stages from './pages/SubmissionStage';
import Child from './pages/caseload/ChildProfile.vue';
import ChildForm from './pages/caseload/ChildForm.vue';
import ChildSearch from './pages/caseload';
import Configuration from './pages/Configuration';
import RiskCriteria from './pages/Configuration/RiskCriteria.vue';
import Categories from './pages/Configuration/Categories.vue';
import History from './pages/History';

//define routes for pages. name is the Subtitle shown in AppHeader.vue
export default [ 
	{
		path: '/prov',
		name:"Provider Management",
		component: ProviderManagement,
		meta: {
			requiresAuth:true,
			typePermissions:['Coordinator'] //if manager, only show users in their provider
		}
	},
	{
		path: '/prov/add',
		name:"Add New Provider",
		component: ProviderForm,
		meta: {
			requiresAuth:true,
			typePermissions:['Coordinator']
		}
	},
	{
		path: '/prov/:id',
		name:"Provider Details",
		component: ProviderForm,
		meta: {
			requiresAuth:true,
			typePermissions:['Coordinator']
		}
	}, 
	{
		path: '/configuration',
		name:"Edit configuration",
		component: Configuration,
		meta: {
			requiresAuth:true,
			typePermissions:['Coordinator']
		}
	},
	{
		path: '/configuration/riskcriteria',
		name:"Edit Risk Criteria",
		component: RiskCriteria,
		meta: {
			requiresAuth:true,
			typePermissions:['Coordinator']
		}
	},
	{
		path: '/configuration/categories',
		name:"Edit Submission Categories",
		component: Categories,
		meta: {
			requiresAuth:true,
			typePermissions:['Coordinator']
		}
	}, 
	{
		path: '/user',
		name:"User Management",
		component: UserManagement,
		meta: {
			requiresAuth:true,
			typePermissions:['Manager','Coordinator']
		}
	},
	{
		path: '/user/add',
		name:"Add New User",
		component: UserForm,
		meta: {
			requiresAuth:true,
			typePermissions:['Manager','Coordinator']
		}
	}, 
	{
		path: '/user/:id',
		name:"User Details",
		component: UserForm,
		meta: {
			requiresAuth:true, 
			typePermissions:['Manager','Coordinator'], 
			personalPage:true
		} //if not coordinator, no provider change. if not manager/coordinator, no type change. Manager cannot assign coordinator type. Other users can only see their own user page.  
	},  //Need to set the Type options available on Server - like getProvOptions
	{ 
		path: '/',
		name:"Submissions",
		component: UserForm,
		meta: {requiresAuth:true}
	},
	{
		path: '/login',
		component: Login,
		meta: {requiresAuth:false}
	},
	{
		path: '/recovery',
		component: PassRecovery,
		meta: {requiresAuth:false}
	},
	{
		path: '/recovery/:id',
		component: PassRecoveryLink,
		meta: {requiresAuth:false}
	}, //reset password via email link - doesn't require being logged in
	{
		path: '/user/:id/reset',
		component: PassRecoveryLink,
		meta: {
			requiresAuth:true,
			personalPage:true
		}//reset password via user Edit page - requires being logged in, and has same permissions as user Edit page
	},
	{
		path: '/home',
		name:"Home",
		component: Landing,
		meta: {requiresAuth:true}
	},
	{
		path: '/sub/add',
		name:"New Request for Funding",
		component: Initial,
		props: true,
		meta: {
			requiresAuth:true,
			typePermissions:['Staff','Manager', 'Coordinator'] 
		}
	},
	{
        path: '/sub/:id',
        name:"Submission Details",
		component: Stages,
		meta: {requiresAuth:true}
	},
	{
		path: '/child/add',
		name:"Child Addition",
		component: ChildForm,
		meta: {
			requiresAuth:true,
			typePermissions:['Staff','Manager', 'Coordinator'] 
		}
	},
	{
		path: '/child/:id',
		name:"Child Profile",
		component: Child,
		meta: {
			requiresAuth:true,
			typePermissions:['Staff','Manager', 'Coordinator']
		}
	},
	{
		path: '/child/:id/edit',
		name:"Edit Child Profile",
		component: ChildForm,
		meta: {
			requiresAuth:true,
			typePermissions:['Staff','Manager', 'Coordinator']
		}
	},
	{
		path: '/child',
		name:"Caseload",
		component: ChildSearch,
		meta: {
			requiresAuth:true,
			typePermissions:['Staff','Manager','Coordinator']
		}
	},
	{
		path: '/history/*', //any History Route
		name:"Changes History",
		component: History,
		meta: {
			requiresAuth:true,
			typePermissions:['Manager','Coordinator']
		}
	},
	{
		path:'*',//if not defined route, redirect to home. This will redirect to login if not logged in.
		redirect: '/home'
	}
];

//typePermissions in route are for redirecting if not matching user Type. There will be in-component Permission checks for different view renders (or different components entirely, like in User Landing)
//Navigation guards and meta for routes
//only load information onto page if credentials match
//don't trust front end - even if Vue variable says userType= Coordinator, always check the cookie and database and vue variables on each request




//look into moving the global navigation guards (in main) into this folder, to keep routing related activities together. 
//look into importing route components within the route definitions for lazy loading like so      component: () => import(/* webpackChunkName: "users" */ './views/Users.vue')
//see https://router.vuejs.org/guide/advanced/lazy-loading.html