<template>
	<section class="container hero-body">
		<div class="container box">
		<h1 class="title ">
			Viewing Change History
			<h2 class="subtitle ">
				Click on History record to view updater's profile
			</h2>
		</h1>

		<ListTable  
			:isLoading="isLoading" 

			:columns="columns" 
			:rows="history" 
			rowRoute="User Details" 

			:pagination="pagination"
      :total-elements="totalElements"
      @change-pagination="pagination = $event"
		/>
		<b-button class="level-item "
			@click="$router.go(-1)"
			type="is-dark" outlined
			size="is-medium"
			icon-left="angle-left">
			Go Back
		</b-button>

		</div>
	</section>
</template>

<script>
import ListTable from '../../components/layout/ListTable.vue'
import API from '@/api.js'


export default {
  components: { ListTable },
	data () {
		return {
			columns: [
				{ field: 'date', label: 'Date',  width: '200', sortable:true},
				{ field: 'description', label: 'Description', sortable:true },
				{ field: 'username', label: 'User', sortable:true }
			],
			pagination: { // Default pagination options
        page: 0,
        size: 10,
        sortBy: 'id',
        sortOrder: 'asc',
      },
      totalElements: 0,

			history: [ //template value prevents "Nothing Here" table view before async completed
			{
				id: null
			} 
			],
			isLoading: null
		}
	},
	computed:{
		submitAddress: function(){
			if(this.$route.path.substr(-1)=='/'){
				return this.$route.path.slice(0,-1)
			}
			return this.$route.path
		},
	},
	watch: { // If change in table data options are detected, update the URL and fetch the data
    pagination: {
      deep: true,
      handler() {
        this.getHistory();
      }
    },
  },
	methods:{
		getHistory () {
			this.isLoading =true
      const payload = this.getPayload();
			API.getHistory(payload).then(response => {
				for (let i of response.content){
					i.date= new Date(i.date).toLocaleDateString('en-NZ')
				}
				this.isLoading = false
				this.history = response.content;
        this.totalElements = response.totalElements;
			})
		},
		getPayload () { // Constructs the request payload object
      return {
        // Pagination options
        page: this.pagination.page,
        size: this.pagination.size,
        sortBy: this.pagination.sortBy,
        sortOrder: this.pagination.sortOrder,

				submitAddress: this.submitAddress
      }
    },
	},
	created(){
		this.getHistory();
	}   
}
</script>
