<template>
	<section class="columns is-centered">
	<form class="hero-body column is-half" @submit.prevent="submitAction()">
		<div class="box has-text-centered">
			<h1 class="title" >
				Password Reset
			</h1>
			<p >Enter the email of your account, and we'll send you a link to reset your password.</p> <!-- Should we make email and username unique on db side? If so, also give client-side feedback to duplicates on userForm.vue-->
			
			<br>
			<b-field label="Email" class="has-text-left">
				<b-input
					required
					:loading=isLoading
					v-model="email"
					icon="envelope"
					size="is-medium">
				</b-input>
			</b-field>
			
			
			<br>
			<b-field class="level">
				<b-button 
					:loading =isLoading
					class="level-item"
					tag="router-link"
					to="/login"
					type='is-dark' outlined 
					size="is-medium"
					icon-left="angle-left">
					Back to Login
				</b-button>
				<b-button 
					:loading =isLoading
					class="level-item"
					type='is-primary'
					native-type="submit" 
					size="is-medium"
					icon-left="angle-right">
					Send Reset Instructions
				</b-button>
			</b-field>
			
			

		</div>
		
	</form>
	</section>



</template>

<script>
import API from '@/api.js'

export default {
	data(){
		return{
			email:null,
			isLoading:false
		}
	},
	methods:{
		submitAction(){
			this.isLoading = true
			API.findRecoveryEmail(this.email).then(response => {
				if (response===true){
					this.isLoading = false
					this.$buefy.dialog.alert({ 
						title: 'Password Reset link sent!',                                 
						message: `Check your email for the password reset link and instructions. <br>This link lasts for 5 minutes. <br> If you haven't recieved it within 5 minutes, please try again.`,
						type: 'is-success',
						confirmText: 'Back to Login',
						onConfirm: () => this.$router.push('/login'),
						hasIcon: true,
						icon: 'envelope',
						size:'is-medium'
					})
				}else{
					console.log("false")
					this.isLoading = false
					this.$buefy.dialog.alert({ 
						title: 'Invalid email',                                 
						message: `The email entered does not match any active users.`,
						type: 'is-danger',
						confirmText: 'Try different email',
						onCancel: () => this.$router.push('/login'),
						canCancel:true,
						cancelText:'Back to Login',
						hasIcon: true,
						icon: 'user-times',
						size:'is-medium'
					})
				}
				
			})
		}
	}

}
</script>

<style>

</style>
