<template>
  <section class="container hero-body">
    <div class="container box">
      <div class="level-right">
        <div class="level-item">
          <b-button 
            tag="router-link"
            to="/prov/add"
            type="is-primary" 
            outlined
            icon-left="plus"
          >
            Add Provider
          </b-button>
        </div> 
        <div class="level-item">
          <b-button 
            tag="router-link"
            to="/user"
            type="is-dark" 
            outlined
            icon-left="user"
          >
            Manage Users
          </b-button>
        </div>
      </div>
    <!-- ref allows the methods within the ListTable child component to be called from here in index, the parent component. -->
      <ListTable 
        ref="providerTable" 
        :isLoading="isLoading" 

        :columns="columns" 
        :rows="providers"
        rowRoute="Provider Details" 

        :pagination="pagination"
        :total-elements="totalElements"
        @change-pagination="pagination = $event"
      />
    </div>
  </section>
</template>

<script>
import ListTable from '../../components/layout/ListTable.vue'
import API from '@/api.js'

export default {
  components: { ListTable },
  data () {
    return {
      columns: [
        { field: 'id', label: '#', width: '1', sortable:true },
        { field: 'organisation', label: 'Provider', width: '500', sortable:true },
      ],
      
      pagination: { // Default pagination options
        page: 0,
        size: 10,
        sortBy: 'id',
        sortOrder: 'asc',
      },
      totalElements: 0,
      
      providers: [ //template value prevents "Nothing Here" table view before async completed
        { //populated on async get request through created hook
          id: null,
          } 
      ],
      isLoading: null
    }
  },
  watch: { // If change in table data options are detected, update the URL and fetch the data
    pagination: {
      deep: true,
      handler() {
        this.getProviders();
      }
    },
  },
  methods:{
    getProviders() {
      const payload = this.getPayload();
      API.getProviderList(payload).then( response => {
        this.loading = false;
        this.providers = response.content;
        this.totalElements = response.totalElements;
      })
    },
    getPayload () { // Constructs the request payload object
      return {
        // Pagination options
        page: this.pagination.page,
        size: this.pagination.size,
        sortBy: this.pagination.sortBy,
        sortOrder: this.pagination.sortOrder
      }
    },
  },
  created() {
    this.getProviders();
  }	 
}
</script>