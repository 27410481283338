<template>
<section>
<form class="hero-body container" @submit.prevent="submitAction()" >
	<div class="box columns is-multiline is-centered">
	<div class="column is-two-thirds">
		<div class="container has-text-centered">
		<h1 class="title is-size-2">
			Edit Categories
		</h1>
		<b-field grouped v-for="category of Categories" v-bind:key="category.id">
			
			
			<b-field>
				<b-checkbox 
				class="alignfix"
				v-model="category.active"
				size="is-large">
				</b-checkbox>
				<b-input
				required
				:disabled="!category.active"
				maxlength="255"
				v-model="category.title"  
				placeholder="Title">
				</b-input>
			</b-field>
			<b-field expanded>
				<b-input
				:disabled="!category.active" 
				v-model="category.description"
				maxlength="255"
				placeholder="Description">
				</b-input>
			</b-field>
		</b-field>
		
		<b-field grouped v-for="(addition,index) in addedCategories" v-bind:key="index">
		<b-field>
			<b-checkbox 
			class="alignfix"
			v-model="addition.active"
			size="is-large">
			</b-checkbox>
			<b-input
			required
			:disabled="!addition.active"
			v-model="addition.title"  
			maxlength="255"
			placeholder="Title">
			</b-input>
		</b-field>
		<b-field expanded>
			<b-input
			:disabled="!addition.active" 
			v-model="addition.description"
			maxlength="255"
			placeholder="Description">
			</b-input>
		</b-field>
		<b-button outlined
			@click="removeCategories(index)"
			type="is-danger" 
			icon-right="times-circle">
		</b-button>
		</b-field>

		<b-button outlined
		@click="addCategories()"
		class="is-pulled-left"
		type="is-primary" 
		icon-right="plus">
		</b-button>
		
		<b-loading :is-full-page=false :active="isLoading"></b-loading> 
		</div>
	</div>
		
	<div class="column is-two-thirds">
		<div class="level">
			<b-button class="level-item "
				@click="$router.go(-1)"
				type="is-dark" outlined
				size="is-medium"
				icon-left="angle-left">
				Go Back
			</b-button>
			<b-button class="level-item "
				:disabled ="!editedValues" 
				type='is-primary'
				native-type="submit" outlined
				size="is-medium"
				icon-left="angle-right">
				Confirm Details
			</b-button>
		</div> 
	</div>

	</div>
	
</form>
</section>



</template>

<script>
import API from '@/api.js'



export default {
	data() {
	return {
		Categories:[
		{
			active:null,
			id:null,
			title: null,
			description:null  
		}
		],
		addedCategories:[
		
		],
		fetchedValues:null,
		isLoading: true
	}
	},
	computed:{
	editedValues: function(){
		return JSON.stringify(this.Categories) !== JSON.stringify(this.fetchedValues) || this.addedCategories.length           
	}
	
	},
	methods:{

	async submitAction(){
		this.isLoading = true
		API.updateCategories(this.Categories.concat(this.addedCategories)).then(response =>{
			this.isLoading=false
			if(response){
				this.$buefy.dialog.alert({ 
						title: 'Categories Updated!',                                 
						message: `Your edits have been saved. You can add and change more categories, or go back to the Configuration Menu.`,
						type: 'is-success',
						confirmText: 'Go Back',
						onConfirm: () => this.$router.push({name: 'Edit configuration'}),
						canCancel: true,
						cancelText:'Continue Editing',
						onCancel:() =>  API.getCategories().then(response =>{ //view is rendered for edit mode
										this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
										this.Categories = response
										this.addedCategories = []
										}),
						hasIcon: true,
						icon: 'pen',
						size:'is-medium'
					})
			}else{
				API.endSession(false).then(response=>{
					this.$router.push('/login')
					return response //avoiding linter
				})
			}
			
		})
	},
	addCategories(){
		this.addedCategories.push( {
			active:true,
			id:null,
			title: null,
			description:null  
		})
	},
	removeCategories(index){
		this.addedCategories.splice(index, 1)
	}
	},
	
	created(){
		API.getCategories().then(response =>{ //view is rendered for edit mode
			this.fetchedValues = JSON.parse(JSON.stringify(response)) //hack to keep formValues and fetched Values independent
			this.Categories = response
			this.isLoading=false
		})
	}
	
}
</script>
<style scoped>
	.alignfix{
		align-items:normal;
	}
</style>