<template>
	<div id="app">
		<AppHeader/>
		<router-view/>
	</div>
</template>

<script>

import AppHeader from './components/layout/AppHeader.vue';

export default {
	name: 'app',
	data() {  //might want to rearrange this, placing it in the root instance main.js, which wil require rewrite of router checks in main and UserForm deactivate checks
		return{
			login:false
		}
	},
	components: {
		'AppHeader': AppHeader
	}
}
</script>

<style lang="scss">
	@import "~bulma/sass/utilities/_all";
	$primary: #8c67ef;
	$primary-invert: findColorInvert($primary);
	
	// Setup $colors to use as bulma classes (e.g. 'is-primary')
	$colors: (
		"white": ($white, $black),
		"black": ($black, $white),
		"light": ($light, $light-invert),
		"dark": ($dark, $dark-invert),
		"primary": ($primary, $primary-invert),
		"info": ($info, $info-invert),
		"success": ($success, $success-invert),
		"warning": ($warning, $warning-invert),
		"danger": ($danger, $danger-invert),
	);

	// Links
	$link: $primary;
	$link-invert: $primary-invert;
	$link-focus-border: $primary;

	//Navbar
	.navbar-menu{
		background-image:none !important;
		background-color: white !important;
		box-shadow: none !important;
	}
	//submit buttons
	.maxwidth{
		width:100%;
	}

	@include touch{
		.button[type="submit"]{
			width:100%;
		}
	}

	svg {
		width: 16px !important;
		height: 16px !important;
	}

	// button[type="submit"] {
	// 	width:50%;
	// }
	// .level .level-item.button{
	// 	width:50%;
	// }
	@import "~bulma";
	@import "~buefy/src/scss/buefy";
</style>
