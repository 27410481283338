<template>
	<section class="columns is-centered">
	<form class="hero-body column is-half" @submit.prevent="submitAction()">
		<div class="box has-text-centered">
			<h1 class="title" >
				Password Reset for {{this.user}}
			</h1>
			<p> Set a new password for this account. Please choose a secure and memorable password. </p> 
			
			<br>
			<b-field label="New Password" class="has-text-left">
				<b-input
					required
					type="password"
					:loading=isLoading
					v-model="password"
					
					size="is-medium">
				</b-input>
			</b-field>
			
			<b-field label="Confirm Password" class="has-text-left">
				<b-input
					required
					type="password"
					:loading=isLoading
					v-model="c_password"
					
					size="is-medium">
				</b-input>
			</b-field>
			
			<br>
			<b-field class="level">
				<b-button v-if="$route.meta.requiresAuth"
					class="level-item"
					tag="router-link"
					:to="`/user/${$parent.login.userID}`"
					type="is-dark" outlined
					size="is-medium"
					icon-left="angle-left">
					Go Back
				</b-button>
				<b-button 
					:disabled=!passwordMatch
					:loading =isLoading
					class="level-item"
					type='is-primary'
					native-type="submit" 
					size="is-medium"
					icon-left="angle-right">
					Confirm Password Reset
				</b-button>
			</b-field>
			
			

		</div>
		
	</form>
	</section>



</template>

<script>
import API from '@/api.js'

export default {
	data(){
		return{
			user:null,
			password:null,
			c_password:null,
			isLoading:false
		}
	},
	computed:{
		passwordMatch: function(){
			return this.password == this.c_password && this.password!=null
		},
		passwordStrong: function(){ //front end validation for strong password
			return true
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {


			if(to.meta.requiresAuth){
				vm.user = vm.$parent.login.username
			}else{
				API.checkRecoveryLink(to.params.id).then(response => {
					if(response !== false){
						vm.user = response
						API.authCheck().then(response=>{
							vm.$parent.login = response.user
						})
					}else{
						vm.$buefy.dialog.alert({ 
							title: 'Invalid Link',                                 
							message: `Your reset password link may have expired. We can send you another, which will last 5 minutes.`,
							type: 'is-danger',
							confirmText: 'Get Password Reset Link',
							onConfirm: () => vm.$router.push('/recovery'),
							hasIcon: true,
							canCancel:true,
							onCancel: () => vm.$router.push('/login'),
							cancelText: 'Back to Login',
							icon: 'times-circle',
							size:'is-medium'
						})
					}       
				})
			}
			
		})
	},
	methods:{
		submitAction(){
			this.isLoading = true
			API.resetPassword({username: this.user, password:this.password}).then(response => {
				if (response===true){
					this.isLoading = false
					this.$router.push(`/user/${this.$parent.login.userID}`)
				
				}
				
			})
		}
	}

}
</script>

<style>

</style>
