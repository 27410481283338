<template>
	<section class="hero is-small is-light is-bold">
	<div class="hero-body level is-mobile">
		<div class="level-item" v-if="this.$route.meta.requiresAuth">
			<Navigation ></Navigation>
		</div>
		<div class="level-item" v-if="!this.$route.meta.requiresAuth">
			<img src="/favicon.ico" alt="Turanga Health">
			<h1  v-if="!this.$route.meta.requiresAuth">Turanga Health</h1>
		</div> 
	</div> <!--  hero-body -->
		
	</section>
</template>

<script>
	import Navigation from '../nav';
	export default {
		data() {
			return {
				}
			},
			components: {
				'Navigation': Navigation
			}
	}
</script>

<style>

</style>
