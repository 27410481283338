<template>
  <section class="container hero-body">
    <div class="container box">
      <div class="level-right">
        <div class="level-item">
          <b-button 
            tag="router-link"
            to="/user/add"
            type="is-primary" 
            outlined
            icon-left="user-plus"
          >
            Add User
          </b-button>
        </div>
        <div class="level-item">
          <b-button v-if="$parent.login.userType=='Coordinator'"
            tag="router-link"
            to="/prov"
            type="is-dark" 
            outlined
            icon-left="sitemap"
          >
            Manage Providers
          </b-button>
        </div>
      </div> 

      <ListTable  
        rowRoute="User Details"
        :isLoading="isLoading" 

        :columns="columns" 
        :rows="users" 

        :pagination="pagination"
        :total-elements="totalElements"
        @change-pagination="pagination = $event"
      />
    </div>
  </section>
</template>

<script>
import ListTable from '../../components/layout/ListTable.vue'
import API from '@/api.js'


export default {
  components: { ListTable },
  data () {
    return {
      columns: [
        { field: 'id', label: '#', width: '1', sortable: true },
        { field: 'type', label: 'User Role', width: '150', sortable: true },
        { field: 'username', label: 'Username', width: '150', sortable: true },
        { field: 'email', label: 'Email', width: '300', sortable: true },
        { field: 'organisation', label: 'Provider', width: '200', sortable: true },
        { field: 'active', label: 'State', width: '150', sortable: true },
      ],
    pagination: { // Pagination options
      page: 0,
      size: 10,
      sortBy: 'id',
      sortOrder: 'asc',
    },
    totalElements: 0,
    users: [ //template value prevents "Nothing Here" table view before async completed
      { //populated on async get request through created hook
        id: null,
      } 
    ],
    isLoading: null
    }
  },
  watch: { // If change in table data options are detected, update the URL and fetch the data
    pagination: {
      deep: true,
      handler() {
        this.getUsers();
      }
    },
  },
  methods:{
    getUsers() {
      const payload = this.getPayload();
      API.getUserList(payload).then( response => {
        this.loading = false;
        this.users = response.content;
        this.totalElements = response.totalElements;
      })
    },
    getPayload () { // Constructs the request payload object
      return {
        // Pagination options
        page: this.pagination.page,
        size: this.pagination.size,
        sortBy: this.pagination.sortBy,
        sortOrder: this.pagination.sortOrder
      }
    },
  },
  created() {
    this.getUsers();
  }	    
}
</script>
