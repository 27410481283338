<template>
	<form class="hero-body container" @submit.prevent="submitAction()" >
		<div class="container box">
			<div class="columns is-multiline"> 
				<div class="column is-full" v-if="['Coordinator', 'Manager'].includes($parent.login.userType)" >
					<div class="level">
						<b-button 
							icon-right="history" 
							type="is-dark" outlined
							tag="router-link"
							:to="`/history/config/view`">
							Change History
						</b-button>
					</div>
				</div>
				<div class="column is-two-thirds">
					<div class="container">
						<h1 class="title is-size-2 has-text-centered">General Configuration</h1>
						<b-field grouped v-for="config of configs" v-bind:key="config.id" class="column is-half is-paddingless">
							<b-field v-bind:label="config.displayName" :label-position="labelPosition" expanded v-if="config.name!=='email_password'">
								<b-input v-model="config.value" ></b-input>
							</b-field>
							<div v-else class="column is-full is-paddingless">
							<b-field v-bind:label="config.displayName" :label-position="labelPosition" expanded >
								<b-input v-model="config.value" :readonly="uneditedPass" required placeholder="Enter a new password" expanded></b-input>
								<b-button outlined
									@click="()=>{
													if (uneditedPass){
														config.value = null
													}else{
														config.value = fetched.email_password.value
													}
													uneditedPass= !uneditedPass
												}"
									type="is-primary" 
									:icon-left="passIcon">
								</b-button>	
							</b-field>
							
							</div>
							
						</b-field>
						<div class="level">
							<b-button class="level-item" expanded
								:disabled ="!editedValues" 
								type='is-primary'
								native-type="submit" outlined
								size="is-medium"
								icon-left="angle-right">
								Confirm Details
							</b-button>
						</div> 
					</div><!--- container --->
				</div><!--- column --->
				<div class="column is-one-thirds buttons">
						<b-button tag="router-link" to="/configuration/categories" type="is-primary" outlined size="is-medium" icon-left="plus">
							Categories
						</b-button>
						<b-button tag="router-link" to="/configuration/riskcriteria" type="is-primary" outlined size="is-medium" icon-left="plus">
							Risk Criteria
						</b-button>



						
				</div><!--- column --->
			</div> <!--- columns --->
		</div><!--- container --->
	</form>
</template>

<script>
import API from '@/api.js'
export default {
	components: {},
	data(){
		return {
			configs: [{
				id: null,
				name: null,
				displayName: null,
				value: null
			}],
			config: {},
			edited: false,
			changes: {},
			fetched: [{
				id: null,
				name: null,
				displayName: null,
				value: null
			}],
			labelPosition: 'on-border',
			isLoading: null,
			uneditedPass:true
		}
	},
	computed:{
		editedValues: function(){
			this.edited = false; /* eslint-disable-line  vue/no-side-effects-in-computed-properties*/
			
			if (this.configs && this.fetched) {
				for (let conf in this.configs ){
					if (this.fetched[conf] && (this.configs[conf].value !== this.fetched[conf].value) ){
						this.edited = true; /* eslint-disable-line  vue/no-side-effects-in-computed-properties*/
						this.changes[this.configs[conf].name] = this.configs[conf].value; /* eslint-disable-line  vue/no-side-effects-in-computed-properties*/
					}
				}
				console.log(this.edited)
			}
			
			return this.edited;
		},
		passIcon: function(){
			if(this.uneditedPass){
				return 'pen'
			}else{
				return 'undo'
			}
		}
	},
	methods:{
		submitAction(){
			this.isLoading = true;
			//console.log(this.changes)
			API.updateConfiguration(this.changes).then(response =>{
				this.$buefy.dialog.alert({ 
					title: 'General Configuration Updated!',
					message: `Your edits have been saved. You can edit General Configuration.`,
					type: 'is-success',
					confirmText: 'OK',
					onConfirm:() => API.getConfiguration().then(response =>{
						this.configs = response;
						this.fetched = JSON.parse(JSON.stringify(response)); // deep clone hack
						this.edited = false;
						this.isLoading = false;
						this.changes = {}
					
					}),
					hasIcon: true,
					icon: 'pen',
					size:'is-medium'
				});
				return response //avoiding linter
			});
		}
	},
	created(){
		API.getConfiguration().then(response => {
			this.configs = response;
			this.fetched = JSON.parse(JSON.stringify(response)); // deep clone hack
			this.isLoading = false;
			return response//avoiding linter
		});
	}
}
</script>
