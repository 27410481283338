<template>
	<section class="columns is-centered">
	<form class="hero-body column is-half" @submit.prevent="submitAction()">
		<div class="box">
			<h1 class="title has-text-centered">
				Login 
			</h1>
			<b-field label="Username">
				<b-input
					required
					:loading=isLoading
					v-model="login.user"
					icon="user"
					size="is-medium">
				</b-input>
			</b-field>
			<br>
			<b-field label="Password">
				<b-input 
					required
					:loading=isLoading
					v-model="login.password"
					icon="key"
					size="is-medium"
					type="password"
					password-reveal>
				</b-input>
			</b-field>
			<br>
			<b-field class="level">
				<b-button 
					:loading =isLoading
					class="level-item "
					expanded
					type='is-primary'
					native-type="submit" outlined
					size="is-medium"
					icon-left="angle-right">
					Sign In 
				</b-button>
			</b-field>
			
			

		</div>
		<h1 class="title has-text-centered is-size-7 has-text-link">
			<router-link to='/recovery'>
				Forgot password? 
			</router-link>
		</h1>
	</form>
	</section>



</template>

<script>
import API from '@/api.js'

export default {
	data(){
		return{
			login:{
				user: null,
				password:null
			},
			isLoading:false
		}
	},
	methods:{
		submitAction(){
			this.isLoading = true
			API.attemptLogin(this.login).then(response => {
				if (response===true){
					this.isLoading = false
					this.$router.push('/home')
				}else{
					this.isLoading = false
					this.$buefy.dialog.alert({ 
						title: 'Incorrect Login details',                                 
						message: `Check both your username and password. `,
						type: 'is-warning',
						confirmText: 'Try again',
						hasIcon: true,
						icon: 'user-times',
						size:'is-medium'
					})
				}
				
			})
		}
	}

}
</script>

<style>

</style>
