<template>
  <a class="navbar-item" :class="sortable ? '' : 'disabled'" role="button" @click="changeSort">
    {{ header }}
    <template v-if="sortable">
      <b-icon v-if="pagination.sortBy === field" :icon="sortDirectionComputed"></b-icon>
      <b-icon v-else type="is-white" icon="chevron-down"></b-icon>
    </template>
  </a>
</template>

<script>
  export default {
    name: 'ListTableHeader',
    props: {
      header: {type: String, required: true },
      field: {type: String, required: true },
      pagination: {type: Object, required: true },
      sortable: {type: Boolean, required: true },
    },
    computed: {
      sortDirectionComputed () {
        return this.pagination.sortOrder === 'asc' ? 'chevron-up' : 'chevron-down'
      }
    },
    methods: {
      changeSort () {
        if (!this.sortable) { // If header isnt sortable, does nothing
          return
        }
        const updatedPagination = {
          page: this.pagination.page,
          size: this.pagination.size,
          sortBy: this.field,
          sortOrder: this.pagination.sortOrder === 'desc' ? 'asc' : 'desc' // sortOrder is flipped if header is sortable
        }
        this.$emit('change-pagination', updatedPagination)
      }
    }
  }
</script>

<style>
.disabled:hover {
  color: #4A4A4A !important;
  background-color: #FFF !important;
  cursor: default;
}
</style>