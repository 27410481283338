<template>
  <div class="level">
      <div class="level-left">
        <template v-if="showFilters">
          <filter-dropdown
            class="level-item"
            :filter-list="filters.stage.availableOptions"
            :selected-filters="filters.stage.selectedOptions"
            :filter-input="filters.stage.stageInput"
            header-name="Stage"
            @change-filter="$emit('change-stage', $event)"
          />
          <filter-dropdown
            class="level-item"
            style="margin-left: 0px;"
            :filter-list="filters.status.availableOptions"
            :selected-filters="filters.status.selectedOptions"
            :filter-input="filters.status.statusInput"
            header-name="Status"
            @change-filter="$emit('change-status', $event)"
          />
          <b-datepicker
            :value="datesComputed"
            placeholder="Filter by Date"
            range
            icon="chevron-down"
            class="level-item"
            @input="$emit('change-date', $event)"
          />
          <b-button
            @click="$emit('reset')">
            Reset Filters
          </b-button>
        </template>
      </div>
    </div>
</template>

<script>
import FilterDropdown from './FilterDropdown.vue';

export default {
  name: "ListTableFilters",
  components: { FilterDropdown },
  props: {
    filters: { type: Object, required: true },
    showFilters: { type: Boolean, default: false },
  },
  methods: {
    test(){
      console.log('test')
    }
  },
  computed: {
    datesComputed() {
      const start = this.filters.date.selectedDates[0] ? new Date(this.filters.date.selectedDates[0]) : null
      const end = this.filters.date.selectedDates[1] ? new Date(this.filters.date.selectedDates[1]) : null
      if (!(start || end)){ // Checks whether dates have been pre-populated
        return null
      }
      return [new Date(start), new Date(end)]
    },
  }
}
</script>