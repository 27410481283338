var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"hero-body container",on:{"submit":function($event){$event.preventDefault();return _vm.submitAction()}}},[_c('div',{staticClass:"container box"},[_c('div',{staticClass:"columns is-multiline"},[(['Coordinator', 'Manager'].includes(_vm.$parent.login.userType))?_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"level"},[_c('b-button',{attrs:{"icon-right":"history","type":"is-dark","outlined":"","tag":"router-link","to":`/history/config/view`}},[_vm._v(" Change History ")])],1)]):_vm._e(),_c('div',{staticClass:"column is-two-thirds"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title is-size-2 has-text-centered"},[_vm._v("General Configuration")]),_vm._l((_vm.configs),function(config){return _c('b-field',{key:config.id,staticClass:"column is-half is-paddingless",attrs:{"grouped":""}},[(config.name!=='email_password')?_c('b-field',{attrs:{"label":config.displayName,"label-position":_vm.labelPosition,"expanded":""}},[_c('b-input',{model:{value:(config.value),callback:function ($$v) {_vm.$set(config, "value", $$v)},expression:"config.value"}})],1):_c('div',{staticClass:"column is-full is-paddingless"},[_c('b-field',{attrs:{"label":config.displayName,"label-position":_vm.labelPosition,"expanded":""}},[_c('b-input',{attrs:{"readonly":_vm.uneditedPass,"required":"","placeholder":"Enter a new password","expanded":""},model:{value:(config.value),callback:function ($$v) {_vm.$set(config, "value", $$v)},expression:"config.value"}}),_c('b-button',{attrs:{"outlined":"","type":"is-primary","icon-left":_vm.passIcon},on:{"click":()=>{
												if (_vm.uneditedPass){
													config.value = null
												}else{
													config.value = _vm.fetched.email_password.value
												}
												_vm.uneditedPass= !_vm.uneditedPass
											}}})],1)],1)],1)}),_c('div',{staticClass:"level"},[_c('b-button',{staticClass:"level-item",attrs:{"expanded":"","disabled":!_vm.editedValues,"type":"is-primary","native-type":"submit","outlined":"","size":"is-medium","icon-left":"angle-right"}},[_vm._v(" Confirm Details ")])],1)],2)]),_c('div',{staticClass:"column is-one-thirds buttons"},[_c('b-button',{attrs:{"tag":"router-link","to":"/configuration/categories","type":"is-primary","outlined":"","size":"is-medium","icon-left":"plus"}},[_vm._v(" Categories ")]),_c('b-button',{attrs:{"tag":"router-link","to":"/configuration/riskcriteria","type":"is-primary","outlined":"","size":"is-medium","icon-left":"plus"}},[_vm._v(" Risk Criteria ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }