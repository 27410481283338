<template>
  <div>
    <list-table-filters
      v-if="filters"
      :filters="filters"
      show-filters
      @change-stage="$emit('change-stage', $event)"
      @change-status="$emit('change-status', $event)"
      @change-date="$emit('change-date', $event)"
      @reset="$emit('reset')"
    />

    <b-table
      thead-class="hidden_header"
      :data="filter"
      :loading="isLoading"
      backend-pagination
      :per-page="pagination.size"
      :total="totalElements"
      backend-sorting

      mobile-sort-placeholder="Sort Rows"
      hoverable
      :row-class="(row) => rowClass(row)"
      @select="selectedRow"
      >
      <template slot-scope="props">
        <b-table-column 
          v-for="(c,index) in columns" 
          :key="index" 
          :field="c.field" 
          :label="c.label" 
          :width="c.width" 
          :visible="c.visible" 
          :centered="c.centered" 
          :numeric="c.numeric"
          :sortable="false"
          >
          <template #header>
            <list-table-header
              :header="c.label"
              :field="c.field"
              :pagination="pagination"
              :sortable="c.sortable"
              @change-pagination="$emit('change-pagination', $event)"
            />
          </template>
            {{ props.row[c.field] }}
        </b-table-column>
      </template>
      <template slot="empty">
        <div class="content has-text-grey has-text-centered">
          <b-icon icon="times-circle" size="is-large"></b-icon>
          <p>Nothing here.</p>
        </div>
      </template>
    </b-table>

    <list-table-pagination
      :pagination="pagination"
      :total-elements="totalElements"
      @change-pagination="$emit('change-pagination', $event)"
    />
    
    <div v-if="isLoading && this.rows.length==1" class="loadingpadd has-text-centered has-text-danger">
      <b v-if="timeOutWarning">This is taking longer than expected... <br> Please try refreshing the page.</b>
      <br v-else>
    </div>
  </div>
</template>

<script>
import ListTableFilters from '../ListTableFilters.vue';
import ListTablePagination from '../ListTablePagination.vue';
import ListTableHeader from '../ListTableHeader.vue';

  export default {
    name: "ListTable",
      props:{
        isLoading: { type: Boolean },
        HighlightValuePair: { type: Object, default: function() { return { 'default': '' } } },
        
        showFilters: {type: Boolean, default: false },
        filters: { type: Object, default: null },
        
        columns: { type: Array, required: true }, 
        rows: { type: Array, required: true }, 
        rowRoute: { type: String }, 

        pagination: {type: Object, required: true },
        totalElements: {type: null, required: true},
      },
    data(){
      return {
        checkedRows:[],
        timeOutLimit: 60000, //60 seconds
        timeOutWarning:false,
      }
    },
    components: {
      ListTableFilters,
      ListTablePagination,
      ListTableHeader,
    },
    computed: {
      filter: function() { //For Searching. Check every value in each row/column, display if matching
        let name_re = new RegExp(this.search_query, 'i')
        let data = []
        if(this.rows.length==1 && this.rows[0].id==null){
          return this.rows
        }
        for (let row of this.rows){
          for (let tableHeading of this.schema){
            
            if(row[tableHeading]!=null){
              if(String(row[tableHeading]).match(name_re)){
                data.push(row)
                break
              }
            }
          }
        }
        return data
      },
      schema: function() { //The key name of data in each column
        let tableHeadings = []
        for (let column of this.columns){
          tableHeadings.push(column.field)
        }
        return tableHeadings
      },
    },
    methods: {
      filterList(field) {
        return this.filters[field]?.availableOptions ?? []
      },
      selectedFilters(field) {
        return this.filters[field]?.selectedOptions ?? []
      },
      selectedDates(field) {
        return this.filters[field]?.selectedDates ?? [null, null]
      },
      selectedRow(item) {
        if (item.username === null) {
          return
        }
        localStorage.clear()
        window.localStorage['queryString'] = history.state?.queryString
        window.localStorage['name'] = history.state?.name
        this.$router.push({ name: this.rowRoute, params: { id:`${item.id}` } })
      },
      onSortChange(field, order){
      this.$emit('change-pagination', { 
        page: this.pagination.page, 
        size: this.pagination.size, 
        sortBy: field, 
        sortOrder: order
        });
      },
      rowClass(row){
        let rowStyle=""
        // console.log(this.HighlightValuePair['status'][row['status']])
        // console.log(Object.keys(this.HighlightValuePair))
        
        if (Object.keys(this.HighlightValuePair)[0]=='default'){ //No styling
          return rowStyle
        }else{
          for (let i of Object.keys(this.HighlightValuePair)){
            //console.log(this.HighlightValuePair[i][row[i]])
            
            if(this.HighlightValuePair[i][row[i]] !== undefined){//this applies a style to matching values of a column
              rowStyle+=` ${this.HighlightValuePair[i][row[i]]}`
            }

            if(this.HighlightValuePair[i]['all']!== undefined){ //this applies a style to all rows
              rowStyle +=` ${this.HighlightValuePair[i]['all']}` 
            }
          }
          return rowStyle
        }
      },
    },
    created(){
      setTimeout(() => this.timeOutWarning = true, this.timeOutLimit)
    }
  }               
</script>

<style>
  th {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .loadingpadd{
    padding-top: 6em;
    padding-bottom: 3em;
  }
    tr.is-success {
        background: #e8fff0;
        color: #363636;
    }
  tr.is-danger {
        background: rgb(255, 242, 244)	;
        color:  #363636;
    }
  tr.is-danger:hover{
    color: #e22d4b;
    }
  tr.is-success:hover {
        color: #47cf73;
    }
  tr.statusMatch.provMatch.stageMatch{
    color:#363636;
    background:rgb(255, 237, 178) !important;
    font-weight:500;
  }
  tr.statusMatch.provMatch.stageMatch:hover{
    color:#ffc118;
    font-weight:500;
    background: white !important;
  }
</style>
