<template>
	
	<div  class="container box">
		<b-navbar class="is-white">
			<template slot="brand">
				
				<img src="/favicon.ico" alt="Turanga Health" >
				
			</template>
			
			<template slot="start">
				<b-navbar-item tag="router-link" 
					v-for="routes in accessibleLinks" 
					v-bind:class="routes.class" 
					v-bind:key="routes.id"
					:to="`${routes.page}`">
					{{routes.text}} 
				</b-navbar-item>
			</template>
			
			<template slot="end">
				<b-navbar-item tag="div">
					<div class="navbar-item">
						<b-button
						@click="logout()"
						type='is-dark' outlined 
						size="is-medium"
						icon-left="sign-out-alt">
							Logout
						</b-button>
					</div>
				</b-navbar-item>
			</template>
		</b-navbar>

		<!-- <nav role="navigation" aria-label="main navigation" class="navbar is-spaced">
			<div class="container">
				<div class="navbar-menu is-active">
					<div class="navbar-start">
						<div class="navbar-brand">
								
						</div>
					</div>
					<router-link v-for="routes in accessibleLinks" 
					v-bind:class="routes.class" v-bind:key="routes.id"
					:to="`${routes.page}`">
						{{routes.text}} 
						
					</router-link>
					<div class="navbar-end">
						
					</div>
				</div>
			</div>
		</nav> -->
	</div> 
</template>

<script>
	import API from '@/api.js';
	export default {
		name: 'Navigation',
		data() {
			return {
				links: [
					{
						id: 0,
						text: 'Home',
						page:'/home',
						class: 'navbar-item',
						access: ['Coordinator', 'Manager', 'Staff', 'Accounts']
					},
					// {
					// 	id: 1,
					// 	text: 'Submission',
					// 	page:'/sub/add',
					// 	class: 'navbar-item',
					// 	access: ['Coordinator', 'Manager', 'Staff', 'Accounts']
					// },
					{
						id: 2,
						text: 'Providers',
						page:'/prov',
						class: 'navbar-item',
						access: ['Coordinator']
					},
					{
						id: 3,
						text: 'Children',
						page:'/child',
						class: 'navbar-item',
						access: ['Coordinator', 'Manager', 'Staff']
					},
					{
						id: 4,
						text: 'Users',
						page:'/user',
						class: 'navbar-item',
						access: ['Coordinator', 'Manager']
					},
					{
						id: 5,
						text: 'Configuration',
						page: '/configuration',
						class: 'navbar-item dropdown is-hoverable',
						subPage: [
							{
								id: 51,
								text: 'Risk Criteria',
								page: '/configuration/riskcriteria',
								access: 'Coordinator'
							},
							{
								id: 52,
								text: 'Categories',
								page: '/configuration/categories',
								access: 'Coordinator'
							}
						],
						access: ['Coordinator']
					},
					{
						id: 6,
						text: 'Account',
						page:`/user/${this.$root.$children[0].login.userID}`, //won't work with computed value
						class: 'navbar-item',
						access: ['Coordinator', 'Manager', 'Staff', 'Accounts']
					}
				]
			}
		},
		methods:{
			logout(){
				API.endSession(false).then(response=>{
					this.$router.push('/login')
					return response
				});
			}
		},
		computed: {
			userType(){
				return this.$root.$children[0].login.userType;
			},
			accessibleLinks(){
				return this.links.filter(link => link.access.includes(this.userType))
			}

		}
	}
</script>
<style scoped>
	/* styling in App.vue */
	
</style>