<template>
  <div>
    <component 
      :is="UserLanding" 
      :default-filters="defaultFiltersComputed"
      :default-pagination="defaultPagination"
      :current-pagination="paginationComputed"
      :search-input="searchInput"
      @change-pagination="changePagination($event)"
    />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        defaultPagination: {
          page: 0,
          size: 10,
          sortBy: 'date',
          sortOrder: 'desc',
          },
        searchInput: '',
        'Coordinator': {
          filters: {
            selectedStages: [],
            selectedStatuses: ['In Progress'],
          },
        },
        'Manager': {
          filters: {
            selectedStages: [],
            selectedStatuses: ['In Progress'],
          }
        },
        'Staff': {
          filters: {
            selectedStages: ['Follow-up'],
            selectedStatuses: ['In Progress'],
          }
        },
        'Accounts': {
          filters: {
            selectedStages: ['Purchasing'],
            selectedStatuses: ['In Progress'],
          }
        }
      }
    },
    methods: {
      changePagination (updatedPagination) { 
        this[this.userType].pagination = updatedPagination;
      },
    },
    computed:{
      userType(){
        return this.$root.$children[0].login.userType
      },
      UserLanding () {
        if(this.userType === 'Coordinator'){
          return () => import('./Coordinator.vue')
        } else if(this.userType === 'Manager'){
          return () => import('./Manager.vue')
        } else if(this.userType === 'Staff'){
          return () => import('./Staff.vue')
        } else if(this.userType === 'Accounts'){
          return () => import('./Accounts.vue')
        }else{
          return () => import('./Accounts.vue') //avoiding linter
        }
      },
      defaultFiltersComputed() {
        return this[this.userType].filters;
      },
      paginationComputed() {
        return this[this.userType].pagination;
      }
    },
  }
</script>
